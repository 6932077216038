<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="保养单号" prop="order_num" style="width: 25%">
              <a-input v-model="queryParams.order_num"></a-input>
            </a-form-model-item>
            <a-form-model-item label="计划完成时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="项目名称" prop="monitorpointname" style="width: 25%">
                <a-input v-model="queryParams.monitorpointname"></a-input>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="equipment_type" style="width: 25%">
                <a-select v-model="queryParams.equipment_type" @change="getEquipmentCategory(queryParams.equipment_type)">
                  <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="是否为消防设备" prop="is_fire_equipment" style="width: 25%">
                <a-select v-model="queryParams.is_fire_equipment">
                  <a-select-option value="是">是</a-select-option>
                  <a-select-option value="否">否</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备编号" prop="equipment_num" style="width: 25%">
                <a-input v-model="queryParams.equipment_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="设备名称" prop="equipment_name" style="width: 25%">
                <a-input v-model="queryParams.equipment_name"></a-input>
              </a-form-model-item>
              <a-form-model-item label="设备类别" prop="equipment_category" style="width: 25%">
                <a-select v-model="queryParams.equipment_category" @change="getEquipmentChildCategory(queryParams.equipment_category)">
                  <a-select-option v-for="(item, index) in equipmentCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备子类别" prop="equipment_child_category" style="width: 25%">
                <a-select v-model="queryParams.equipment_child_category">
                  <a-select-option v-for="(item, index) in equipmentChildCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保单位" prop="maintenance_unit" style="width: 25%">
                <a-select v-model="queryParams.maintenance_unit" show-search :filter-option="filterOption">
                  <a-select-option v-for="(item, index) in maintenanceUnitList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="保养周期" prop="maintenance_period" style="width: 25%">
                <a-select v-model="queryParams.maintenance_period">
                  <a-select-option v-for="(item, index) in maintenancePeriodList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="派单提前时间" prop="dispatch_duration" style="width: 25%">
                <a-select v-model="queryParams.dispatch_duration">
                  <a-select-option value="1">提前一天以内</a-select-option>
                  <a-select-option value="2">提前一天以上</a-select-option>
                  <a-select-option value="3">超时派单</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="接单时长" prop="take_duration" style="width: 25%">
                <a-select v-model="queryParams.take_duration">
                  <a-select-option value="1"><=24小时</a-select-option>
                  <a-select-option value="2"><=48小时</a-select-option>
                  <a-select-option value="3">>48小时</a-select-option>
                  <a-select-option value="4">>48小时未接单</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="保养时间偏差" prop="maintenance_time_deviation" style="width: 25%">
                <a-select v-model="queryParams.maintenance_time_deviation">
                  <a-select-option value="1">提前完成</a-select-option>
                  <a-select-option value="2">计划当天</a-select-option>
                  <a-select-option value="3">延期一天之内</a-select-option>
                  <a-select-option value="4">延期三天之内</a-select-option>
                  <a-select-option value="5">延期七天之内</a-select-option>
                  <a-select-option value="6">延期大于七天</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="保养结论" prop="result_code" style="width: 25%">
                <a-select v-model="queryParams.result_code">
                  <a-select-option value="1">正常</a-select-option>
                  <a-select-option value="2">保养后正常</a-select-option>
                  <a-select-option value="3">报修（带病运行）</a-select-option>
                  <a-select-option value="4">报修（停止运行）</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="维保自评价" prop="group_man_audit_grade" style="width: 25%">
                <a-select v-model="queryParams.group_man_audit_grade">
                  <a-select-option value="1">不合格</a-select-option>
                  <a-select-option value="2">合格</a-select-option>
                  <a-select-option value="3">良好</a-select-option>
                  <a-select-option value="4">优秀</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="物业评价" prop="property_audit_grade" style="width: 25%">
                <a-select v-model="queryParams.property_audit_grade">
                  <a-select-option value="1">不合格</a-select-option>
                  <a-select-option value="2">合格</a-select-option>
                  <a-select-option value="3">良好</a-select-option>
                  <a-select-option value="4">优秀</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="节点状态" prop="process_node" style="width: 25%">
                <a-select v-model="queryParams.process_node">
                  <a-select-option value="">所有</a-select-option>
                  <a-select-option value="20">待组长派单</a-select-option>
                  <a-select-option value="30">待组员派单</a-select-option>
                  <a-select-option value="40">待组员转单</a-select-option>
                  <a-select-option value="50">待签到</a-select-option>
                  <a-select-option value="60">待维保</a-select-option>
                  <a-select-option value="70">待组长审核</a-select-option>
                  <a-select-option value="71">完成</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="年份" prop="year" style="width: 25%">
                <a-select v-model="queryParams.order_date">
                  <a-select-option v-for="(item, index) in yearOptions" :key="index" :value="item">{{item+'年'}}</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="作业时间状态" prop="order_exception" style="width: 25%">
                <a-select v-model="queryParams.order_exception">
                  <a-select-option value="">所有</a-select-option>
                  <a-select-option value="0">正常</a-select-option>
                  <a-select-option value="1">异常</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-row>
          <a-col :span="6"><a-statistic title="纳入保养率（仅对”公司/项目“、”设备类型“条件敏感）" :value="statistics.maintenance_rate?statistics.maintenance_rate+'%':0+'%'" style="margin-right: 10px" /></a-col>
          <a-col :span="6"><a-statistic title="计划保养数量" :value="statistics.maintenance_total" style="margin-right: 10px" /></a-col>
          <a-col :span="6"><a-statistic title="按时完成数量" :value="statistics.finish_total" style="margin-right: 10px" /></a-col>
          <a-col :span="6"><a-statistic title="按时保养率" :value="statistics.on_time_rate?statistics.on_time_rate+'%':0+'%'" style="margin-right: 10px" /></a-col>
        </a-row>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}（保养条数：{{pagination.total}}条）</h3>
          <div class="table-btns">
            <a-button @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" :rowKey="(record,index)=> index" :scroll="{ x: 1500 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
          <span slot="maintenance_period" slot-scope="value">
            <span :title="value?maintenancePeriodList[value].dicvalue:''">{{value?maintenancePeriodList[value].dicvalue:''}}</span>
          </span>
          <span slot="equipment_num" slot-scope="value,record">{{value}}</span>
          <span slot="result_code" slot-scope="value">
            <span :style="value?value===3||value===4?{'color':'red'}:{'color':''}:{'color':''}" :title="value?resultCode[value]:''">{{resultCode[value]}}</span>
          </span>
          <span slot="group_man_audit_grade" slot-scope="value">
            <span :style="value?value==='1'?{'color':'red'}:{'color':''}:{'color':''}" :title="value?gradeList[value]:''">{{gradeList[value]}}</span>
          </span>
          <span slot="property_audit_grade" slot-scope="value">
            <span :style="value?value==='1'?{'color':'red'}:{'color':''}:{'color':''}" :title="value?gradeList[value]:''">{{gradeList[value]}}</span>
          </span>
          <span slot="dispatch_duration" slot-scope="value,record">
            <span :style="value?value!=='0'&&value.indexOf('-')?{'color':'red'}:{'color':''}:{'color':''}" :title="value?record.dispatch_duration:''">{{record.dispatch_duration}}</span>
          </span>
          <span slot="check_time" slot-scope="value,record">
            <span :style="value?(moment(record.check_end_time)-moment(record.check_start_time)>86400000||moment(record.check_end_time)-moment(record.check_start_time)<180000)?{'color':'red'}:{'color':''}:{'color':''}" :title="value?record.check_time:''">{{record.check_time}}</span>
          </span>
          <span slot="take_duration" slot-scope="value,record">
            <span :style="value?moment(record.take_time)-moment(record.need_finish_time)>0?{'color':'red'}:{'color':''}:{'color':''}" :title="value?record.take_duration:''">{{record.take_duration}}</span>
          </span>
          <span slot="order_exception" slot-scope="value">
            <a-tag :color="orderExceptionColors[value]||''">{{orderException[value]||''}}</a-tag>
          </span>
          <span slot="maintenance_time_deviation" slot-scope="value,record">
            <span :style="value?value!=='0'?{'color':'red'}:{'color':''}:{'color':''}" :title="value?record.maintenance_time_deviation:''">{{record.maintenance_time_deviation}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.maintenance_order_id">详情</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, measure_range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <equip-maintenance-modal :visible.sync="equipModalVisible" :show-type="equipModalShowType" :ActiveKey="activeKey" :detailData="equipModalDetailData" @get-operation-result="getTableData"></equip-maintenance-modal>
    <detail-modal :visible.sync="modalVisible" :detail-data="modalDetailData" @get-operation-result="getTableData"></detail-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {
  exportMaintenanceOrderInfoExcel,
  getMaintenanceOrderInfoListByCondition, getMaintenanceOrderInfoStatistics,
  getMaintenanceUnitListByCondition
} from "A/maintenance";
import {resultCode} from "@/json/orderSteps";
import {gradeList} from "@/json/orderSteps";
import DetailModal from "V/facilityAndEquipment/maintenanceManagement/maintenance/task/DetailModal";
import equipMaintenanceModal from "V/dataAnalysis/equipMaintenanceModal";
import {orderException, orderExceptionColors} from "@/json/maintenance";
import {getSecondaryDeviceList, getThirdDeviceList} from "A/jcgn";
export default {
  name: "equipMaintenanceReport",
  mixins: [deptselect,pagination],
  components: {
    equipMaintenanceModal,
    DetailModal,
  },
  data() {
    return {
      moment,
      modalDetail:{},
      userdepidCascaderSelected: [],
      resultCode,
      gradeList,
      orderException,
      orderExceptionColors,
      yearOptions: [],
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryInstallDate:null,
      monitorpointList:[],
      deviceCodeList:[],
      maintenancePeriodList:[],
      maintenanceUnitList:[],
      equipmentCategoryList:[],
      equipmentChildCategoryList:[],
      queryParams:{
        monitorpointname:'',
        order_num:'',
        equipment_num:'',
        is_fire_equipment:'',
        equipment_name:'',
        equipment_type:'',
        equipment_category:'',
        equipment_child_category:'',
        maintenance_unit:'',
        maintenance_period:'',
        dispatch_duration:'',
        take_duration:'',
        maintenance_time_deviation:'',
        result_code:'',
        group_man_audit_grade:'',
        property_audit_grade:'',
        starttime:'',
        endtime:'',
        order_date:'',
        order_exception:'',
        process_node:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '保养单号',
          dataIndex: 'order_num',
          key: 'order_num',
          width: 100,
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'equipment_type',
          key: 'equipment_type',
          ellipsis: true,
          align:'center',
          width: 70,
          scopedSlots: { customRender: 'equipment_type' },
        },
        {
          title: '设备编号',
          dataIndex: 'equipment_num',
          key: 'equipment_num',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'equipment_num' },
        },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
          width: 250,
        },
        {
          title: '维保单位',
          dataIndex: 'maintenance_unit_name',
          key: 'maintenance_unit_name',
          ellipsis: true,
          width: 100,
        },
        {
          title: '保养周期',
          dataIndex: 'maintenance_period',
          key: 'maintenance_period',
          ellipsis: true,
          width: 70,
          scopedSlots: { customRender: 'maintenance_period' },
        },
        {
          title: '年份',
          dataIndex: 'year',
          key: 'year',
          ellipsis: true,
          width: 50,
        },
        {
          title: '派单提前时间',
          dataIndex: 'dispatch_duration',
          key: 'dispatch_duration',
          ellipsis: true,
          scopedSlots: { customRender: 'dispatch_duration' },
        },
        {
          title: '接单时长',
          dataIndex: 'take_duration',
          key: 'take_duration',
          ellipsis: true,
          scopedSlots: { customRender: 'take_duration' },
        },
        {
          title: '保养时间偏差',
          dataIndex: 'maintenance_time_deviation',
          key: 'maintenance_time_deviation',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenance_time_deviation' },
        },
        {
          title: '保养用时',
          dataIndex: 'check_time',
          key: 'check_time',
          ellipsis: true,
          scopedSlots: { customRender: 'check_time' },
        },
        {
          title: '作业时间状态',
          dataIndex: 'order_exception',
          key: 'order_exception',
          ellipsis: true,
          scopedSlots: { customRender: 'order_exception' },
        },
        {
          title: '保养结论',
          dataIndex: 'result_code',
          key: 'result_code',
          ellipsis: true,
          width: 70,
          scopedSlots: { customRender: 'result_code' },
        },
        {
          title: '维保单位自评',
          dataIndex: 'group_man_audit_grade',
          key: 'group_man_audit_grade',
          ellipsis: true,
          width:100,
          scopedSlots: { customRender: 'group_man_audit_grade' },
        },
        {
          title: '物业评价',
          dataIndex: 'property_audit_grade',
          key: 'property_audit_grade',
          ellipsis: true,
          width:70,
          scopedSlots: { customRender: 'property_audit_grade' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      statistics:{
        maintenance_rate:'',
        maintenance_total:'',
        finish_total:'',
        on_time_rate:'',
      },
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
      activeKey:'5',
      equipModalVisible: false,
      equipModalShowType: '',
      equipModalDetailData: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "设施设备保养分析报表"
    },
    breadcrumb() {
      const pages = [{name:"数据分析与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
      this.queryParams.order_date=start.substring(0,4)
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).format("YYYYMMDD");
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      this.usertype=logininfo.usertype;
    }
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.maintenancePeriodList = dictionary.maintenancePeriodList
      this.deviceCodeList = dictionary.deviceCodeList
    }
    this.getYearOptions()
    this.getTableData()
    this.initDeptOptionsAll();
    this.getMonitorPointNameList();
  },
  methods:{
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
      getMaintenanceUnitListByCondition({userdepsid:this.userInfo.userdepid}).then(res=> {
        if (res && res.returncode == '0') {
          this.maintenanceUnitList = res.item
        }
      })
    },
    getEquipmentCategory(devicecode){
      getSecondaryDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentCategoryList=res.item
        }
      })
    },
    getEquipmentChildCategory(devicecode){
      getThirdDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentChildCategoryList=res.item
        }
      })
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMaintenanceOrderInfoListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
          this.getMaintenanceStatistics()
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    getMaintenanceStatistics(){
      getMaintenanceOrderInfoStatistics({...this.queryParams}).then(res=>{
        if (res && res.returncode == '0') {
          if(res.item.length>0){
            this.statistics=res.item[0]
          }else{
            this.statistics={
              maintenance_rate:'',
              maintenance_total:'',
              finish_total:'',
              on_time_rate:'',
            }
          }
        }
      })
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData = record
            this.modalShowType = "detail"
            this.modalVisible = true
          },
        },
      }
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.getYearOptions()
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let maintenance_order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenance_order_id', maintenance_order_id);
      if(type == 'delete') {
        this.deleteConfirm(maintenance_order_id, record)
      }else if(type == 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    showModal(record){
      this.equipModalDetailData=record
      this.equipModalShowType="detail"
      this.activeKey='5'
      this.equipModalVisible=true
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportMeterReading()
      }).catch(()=>{
      });
    },
    exportMeterReading(){
      let params = {
        ...this.queryParams,
      }
      this.showLoading();
      exportMaintenanceOrderInfoExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    getYearOptions() {
      this.yearOptions = [];
      let currentYear = moment().year();
      let startYear = 2023;
      for(let i=100; i>-1; i--) {
        this.yearOptions.push(startYear+i+'');
      }
      this.queryParams.order_date=currentYear+''
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
}
</script>
<style scoped>

</style>