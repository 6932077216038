<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1200" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit' || showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:8}" :wrapper-col="{span:16}">
        <a-tabs type="card" v-model="activeKey">
<!--          <a-tab-pane key="1" tab="基础信息"></a-tab-pane>-->
<!--          <a-tab-pane key="2" tab="设备台账"></a-tab-pane>-->
<!--          <a-tab-pane key="3" tab="主要参数"></a-tab-pane>-->
<!--          <a-tab-pane key="4" tab="设备标识卡"></a-tab-pane>-->
          <a-tab-pane key="5" tab="保养记录"></a-tab-pane>
        </a-tabs>
        <div v-show="activeKey == '1'">
          <!-- =======================基础信息======================= -->
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">基础信息</h3>
          <a-form-model-item label="项目名称" prop="monitorpointnum">
            <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择">
              <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备名称" prop="equipment_name">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.equipment_name" />
          </a-form-model-item>
          <a-form-model-item label="设备类型" prop="equipment_type">
            <a-select :disabled="showType=='detail'" v-model="formData.equipment_type"  @change="getEquipmentCategory(formData.equipment_type)">
              <a-select-option v-for="(item, index) in deviceCodeList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备类别" prop="equipment_category">
            <a-select :disabled="showType=='detail'" v-model="formData.equipment_category"  @change="getEquipmentChildCategory(formData.equipment_category)">
              <a-select-option v-for="(item, index) in equipmentCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备子类别" prop="equipment_child_category">
            <a-select :disabled="showType=='detail'" v-model="formData.equipment_child_category"  @change="getEquipmentCountByChildCategory">
              <a-select-option v-for="(item, index) in equipmentChildCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否为消防设备" prop="is_fire_equipment">
            <a-select :disabled="showType=='detail'" v-model="formData.is_fire_equipment" @change="createEquipmentNum">
              <a-select-option value="是">是</a-select-option>
              <a-select-option value="否">否</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备编号" prop="equipment_num">
            <a-input :disabled="showType=='detail'||showType=='edit'" v-model.trim="formData.equipment_num" :addon-before="equipment_num_before" :maxLength="11"/>
          </a-form-model-item>
          <a-form-model-item label="档案编号" prop="archives_num">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.archives_num" />
          </a-form-model-item>
          <a-form-model-item label="设备级别" prop="equipment_level">
            <a-select :disabled="showType=='detail'" v-model="formData.equipment_level">
              <a-select-option value="A">A</a-select-option>
              <a-select-option value="B">B</a-select-option>
              <a-select-option value="C">C</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="设备状态" prop="equipment_status">
            <a-select :disabled="showType=='detail'" v-model="formData.equipment_status">
              <a-select-option v-for="(item, index) in equipmentStatusList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="安装位置" prop="address">
            <a-mentions  :disabled="showType=='detail'" :prefix="['']" v-model="formData.address" placeholder="" @change="onChange">
              <a-mentions-option value="调度中心">调度中心</a-mentions-option>
              <a-mentions-option value="高压房">高压房</a-mentions-option>
              <a-mentions-option value="低压配电房">低压配电房</a-mentions-option>
              <a-mentions-option value="配电间">配电间</a-mentions-option>
              <a-mentions-option value="发电机房">发电机房</a-mentions-option>
              <a-mentions-option value="储油间">储油间</a-mentions-option>
              <a-mentions-option value="烟气处理间">烟气处理间</a-mentions-option>
              <a-mentions-option value="机房">机房</a-mentions-option>
              <a-mentions-option value="弱电间">弱电间</a-mentions-option>
              <a-mentions-option value="生活水泵房">生活水泵房</a-mentions-option>
              <a-mentions-option value="消防泵房">消防泵房</a-mentions-option>
              <a-mentions-option value="消防稳压泵房">消防稳压泵房</a-mentions-option>
              <a-mentions-option value="水处理泵房">水处理泵房</a-mentions-option>
              <a-mentions-option value="污水泵房">污水泵房</a-mentions-option>
              <a-mentions-option value="气浮间">气浮间</a-mentions-option>
              <a-mentions-option value="强电井">强电井</a-mentions-option>
              <a-mentions-option value="弱电井">弱电井</a-mentions-option>
              <a-mentions-option value="水管井">水管井</a-mentions-option>
              <a-mentions-option value="冷水机房">冷水机房</a-mentions-option>
              <a-mentions-option value="锅炉房">锅炉房</a-mentions-option>
              <a-mentions-option value="冷却塔间">冷却塔间</a-mentions-option>
              <a-mentions-option value="空气处理机房">空气处理机房</a-mentions-option>
              <a-mentions-option value="送/排风机房">送/排风机房</a-mentions-option>
              <a-mentions-option value="电梯机房">电梯机房</a-mentions-option>
              <a-mentions-option value="气体灭火间">气体灭火间</a-mentions-option>
              <a-mentions-option value="气体间">气体间</a-mentions-option>
              <a-mentions-option value="楼层">楼层</a-mentions-option>
              <a-mentions-option value="天面">天面</a-mentions-option>
              <a-mentions-option value="避难层">避难层</a-mentions-option>
              <a-mentions-option value="架空层">架空层</a-mentions-option>
              <a-mentions-option value="活动区">活动区</a-mentions-option>
            </a-mentions>
            <span style="color: gray;font-size: 10px">请在键盘上按下空格键弹出默认安装位置，也可直接编辑安装位置</span>
          </a-form-model-item>
          <a-form-model-item label="用途" prop="purpose">
            <textarea :disabled="showType=='detail'" v-model.trim="formData.purpose" style="width: 305px"/>
          </a-form-model-item>
<!--          <a-form-model-item label="设备二维码" prop="qrcode">-->
<!--            <div id="qrCode" ref="qrCodeDiv"></div>-->
<!--          </a-form-model-item>-->
        </div>
        <div v-show="activeKey == '2'">
          <!-- =======================设备台账======================= -->
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">设备台账</h3>
          <a-form-model-item label="设备名称" prop="equipment_name">
            <a-input :disabled="true" v-model.trim="formData.equipment_name" />
          </a-form-model-item>
          <a-form-model-item label="型号规格" prop="model_specification">
            <a-input :disabled="showType=='detail'" v-model="formData.model_specification"/>
          </a-form-model-item>
          <a-form-model-item label="出厂编号" prop="factory_no" v-if="formData.equipment_num.substr(-1)=='D'">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.factory_no" />
          </a-form-model-item>
          <a-form-model-item label="制造厂商" prop="manufacturer">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.manufacturer" />
          </a-form-model-item>
          <a-form-model-item label="产地" prop="origin_area">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.origin_area" />
          </a-form-model-item>
          <a-form-model-item label="制造日期" prop="produce_time" v-if="formData.equipment_num.substr(-1)=='D'">
            <a-date-picker :disabled="showType=='detail'" v-model="produceTime"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="外形尺寸" prop="overall_dimension">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.overall_dimension" />
          </a-form-model-item>
          <a-form-model-item label="重量（kg）" prop="weight" v-if="formData.equipment_num.substr(-1)=='D'">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.weight"/>
          </a-form-model-item>
          <a-form-model-item label="原价值" prop="original_value">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.original_value" />
          </a-form-model-item>
          <a-divider></a-divider>
          <a-form-model-item label="设备编号" prop="equipment_num">
            <a-input :disabled="true" v-model.trim="formData.equipment_num" :addon-before="equipment_num_before" />
          </a-form-model-item>
          <a-form-model-item label="图位号" prop="draw_num">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.draw_num" />
          </a-form-model-item>
          <a-form-model-item label="设备类别" prop="equipment_category">
            <a-select :disabled="true" v-model="formData.equipment_category">
              <a-select-option v-for="(item, index) in equipmentCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="安装位置" prop="address">
            <a-mentions :disabled="true" :prefix="['']" v-model="formData.address" placeholder="" @change="onChange">
              <a-mentions-option value="调度中心">调度中心</a-mentions-option>
              <a-mentions-option value="高压房">高压房</a-mentions-option>
              <a-mentions-option value="低压配电房">低压配电房</a-mentions-option>
              <a-mentions-option value="配电间">配电间</a-mentions-option>
              <a-mentions-option value="发电机房">发电机房</a-mentions-option>
              <a-mentions-option value="储油间">储油间</a-mentions-option>
              <a-mentions-option value="烟气处理间">烟气处理间</a-mentions-option>
              <a-mentions-option value="机房">机房</a-mentions-option>
              <a-mentions-option value="弱电间">弱电间</a-mentions-option>
              <a-mentions-option value="生活水泵房">生活水泵房</a-mentions-option>
              <a-mentions-option value="消防泵房">消防泵房</a-mentions-option>
              <a-mentions-option value="消防稳压泵房">消防稳压泵房</a-mentions-option>
              <a-mentions-option value="水处理泵房">水处理泵房</a-mentions-option>
              <a-mentions-option value="污水泵房">污水泵房</a-mentions-option>
              <a-mentions-option value="气浮间">气浮间</a-mentions-option>
              <a-mentions-option value="强电井">强电井</a-mentions-option>
              <a-mentions-option value="弱电井">弱电井</a-mentions-option>
              <a-mentions-option value="水管井">水管井</a-mentions-option>
              <a-mentions-option value="冷水机房">冷水机房</a-mentions-option>
              <a-mentions-option value="锅炉房">锅炉房</a-mentions-option>
              <a-mentions-option value="冷却塔间">冷却塔间</a-mentions-option>
              <a-mentions-option value="空气处理机房">空气处理机房</a-mentions-option>
              <a-mentions-option value="送/排风机房">送/排风机房</a-mentions-option>
              <a-mentions-option value="电梯机房">电梯机房</a-mentions-option>
              <a-mentions-option value="气体灭火间">气体灭火间</a-mentions-option>
              <a-mentions-option value="气体间">气体间</a-mentions-option>
              <a-mentions-option value="楼层">楼层</a-mentions-option>
              <a-mentions-option value="天面">天面</a-mentions-option>
              <a-mentions-option value="避难层">避难层</a-mentions-option>
              <a-mentions-option value="架空层">架空层</a-mentions-option>
              <a-mentions-option value="活动区">活动区</a-mentions-option>
            </a-mentions>
          </a-form-model-item>
          <a-form-model-item label="数量" prop="equipment_quantity" v-if="formData.equipment_num.substr(-1)=='P'">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.equipment_quantity" />
          </a-form-model-item>
          <a-form-model-item label="操作部门" prop="operation_department" v-if="formData.equipment_num.substr(-1)=='D'">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.operation_department" />
          </a-form-model-item>
          <a-form-model-item label="设备级别" prop="equipment_level">
            <a-select :disabled="true" v-model="formData.equipment_level">
              <a-select-option value="A">A</a-select-option>
              <a-select-option value="B">B</a-select-option>
              <a-select-option value="C">C</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="档案编号" prop="archives_num" v-if="formData.equipment_num.substr(-1)=='D'">
            <a-input :disabled="true" v-model.trim="formData.archives_num" />
          </a-form-model-item>
          <a-form-model-item label="启用时间" prop="enable_time" v-if="formData.equipment_num.substr(-1)=='D'">
            <a-date-picker :disabled="showType=='detail'" v-model="enableTime"></a-date-picker>
          </a-form-model-item>
          <a-form-model-item label="参数类别" prop="equipment_param_type">
            <a-select :disabled="showType=='detail'" v-model="formData.equipment_param_type">
              <a-select-option v-for="(item, index) in equipmentParamsList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="formData.is_fire_equipment=='是'" label="防火分区" prop="fire_compartment">
            <a-input :disabled="showType=='detail'" v-model.trim="formData.fire_compartment"  style="width: 305px"/>
          </a-form-model-item>
          <a-form-model-item label="用途" prop="purpose">
            <textarea :disabled="true" v-model.trim="formData.purpose"  style="width: 305px"/>
          </a-form-model-item>
          <a-divider></a-divider>
          <a-form-model-item label="备注" prop="remarks">
            <textarea :disabled="showType=='detail'" v-model.trim="formData.remarks"  style="width: 305px"/>
          </a-form-model-item>
          <a-form-model-item label="设备图片" prop="equipment_pic">
            <a-upload
                :disabled="showType==='detail'"
                name="fileList"
                :headers="uploadHeaders"
                :data="uploadFacilityEquipment"
                list-type="picture-card"
                action="/upload"
                :file-list="equipmentPicList"
                @preview="handlePreview(formData.equipment_pic)"
                @change="equipmentPicUpload">
<!--              <img alt="example" @click="handlePreview(formData.equipment_pic)" style="width: 300px" :src="previewImage" />-->
              <a-button v-if="showType!='detail'"><a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
        </div>
        <div v-show="activeKey == '3'">
          <!-- =====================高压柜=================== -->
          <div v-if="formData.equipment_param_type=='0'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="电压等级（KV）" prop="voltage_level">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_level" />
            </a-form-model-item>
            <a-form-model-item label="使用条件" prop="conditions">
              <a-select :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.conditions">
                <a-select-option value="户内">户内</a-select-option>
                <a-select-option value="户外">户外</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="内部结构" prop="internal_structure">
              <a-select :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.internal_structure">
                <a-select-option value="抽出式">抽出式</a-select-option>
                <a-select-option value="固定式">固定式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.function">
                <a-select-option value="进线">进线</a-select-option>
                <a-select-option value="出线">出线</a-select-option>
                <a-select-option value="市/发电转换">市/发电转换</a-select-option>
                <a-select-option value="联络">联络</a-select-option>
                <a-select-option value="动力">动力</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="进线回路数" prop="incoming_circuit_num">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.incoming_circuit_num" />
            </a-form-model-item>
            <a-form-model-item label="进线方式" prop="incoming_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.incoming_mode" />
            </a-form-model-item>
            <a-form-model-item label="出线回路数" prop="outgoing_circuits_num">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.outgoing_circuits_num" />
            </a-form-model-item>
            <a-form-model-item label="出线方式" prop="outgoing_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.outgoing_mode" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">开关</h3>
            <a-form-model-item label="型号" prop="switch_model">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_model" />
            </a-form-model-item>
            <a-form-model-item label="标准代号" prop="switch_standard_code">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_standard_code" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="switch_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="switch_form">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_form" />
            </a-form-model-item>
            <a-form-model-item label="防护等级" prop="switch_protection_grade">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_protection_grade" />
            </a-form-model-item>
            <a-form-model-item label="短路关合电流（KA）" prop="switch_short_circuit_current">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_short_circuit_current" />
            </a-form-model-item>
            <a-form-model-item label="短路关合耐受电流/时间" prop="switch_short_circuit_withstand_current">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_short_circuit_withstand_current" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="switch_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_rated_current" />
            </a-form-model-item>
            <a-form-model-item label="供电范围" prop="switch_power_supply">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.switch_power_supply" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">电压互感器</h3>
              <a-form-model-item label="型号" prop="voltage_transformer_model">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_model" />
            </a-form-model-item>
            <a-form-model-item label="额定容量（VA）" prop="voltage_transformer_rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_rated_capacity" />
            </a-form-model-item>
            <a-form-model-item label="电压变比" prop="voltage_transformer_ratio">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_ratio" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="voltage_transformer_num">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="voltage_transformer_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="一次额定电压（KV）" prop="voltage_transformer_rated_voltage1">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_rated_voltage1" />
            </a-form-model-item>
            <a-form-model-item label="准确度等级" prop="voltage_transformer_accuracy_level">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_accuracy_level" />
            </a-form-model-item>
            <a-form-model-item label="二次额定电压（KV）" prop="voltage_transformer_rated_voltage2">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.voltage_transformer_rated_voltage2" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">电流互感器</h3>
            <a-form-model-item label="型号" prop="current_transformer_model">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_model" />
            </a-form-model-item>
            <a-form-model-item label="额定容量（VA）" prop="current_transformer_rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_rated_capacity" />
            </a-form-model-item>
            <a-form-model-item label="电流变比" prop="current_transformer_ratio">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_ratio" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="current_transformer_num">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="current_transformer_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_voltage" />
            </a-form-model-item>
            <a-form-model-item label="一次额定电流（A）" prop="current_transformer_rated_current1">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_rated_current1" />
            </a-form-model-item>
            <a-form-model-item label="准确度等级" prop="current_transformer_accuracy_level">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_accuracy_level" />
            </a-form-model-item>
            <a-form-model-item label="二次额定电流（A）" prop="current_transformer_rated_current2">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.current_transformer_rated_current2" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">零序电流互感器</h3>
            <a-form-model-item label="型号" prop="zero_current_transformer_model">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_model" />
            </a-form-model-item>
            <a-form-model-item label="额定容量（VA）" prop="zero_current_transformer_rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_rated_capacity" />
            </a-form-model-item>
            <a-form-model-item label="电流变比" prop="zero_current_transformer_ratio">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_ratio" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="zero_current_transformer_num">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="zero_current_transformer_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_voltage" />
            </a-form-model-item>
            <a-form-model-item label="一次额定电流（A）" prop="zero_current_transformer_rated_current1">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_rated_current1" />
            </a-form-model-item>
            <a-form-model-item label="准确度等级" prop="zero_current_transformer_accuracy_level">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_accuracy_level" />
            </a-form-model-item>
            <a-form-model-item label="二次额定电流（A）" prop="zero_current_transformer_rated_current2">
              <a-input :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.zero_current_transformer_rated_current2" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="highVoltageCabinetParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================直流柜=================== -->
          <div v-if="formData.equipment_param_type=='1'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="输入电压（V）" prop="input_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="DCScreenParams.input_voltage" />
            </a-form-model-item>
            <a-form-model-item label="输出电压（V）" prop="output_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="DCScreenParams.output_voltage" />
            </a-form-model-item>
            <a-form-model-item label="输出电流（A）" prop="output_current">
              <a-input :disabled="showType=='detail'" v-model.trim="DCScreenParams.output_current" />
            </a-form-model-item>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="DCScreenParams.function">
                <a-select-option value="高压机构操作">高压机构操作</a-select-option>
                <a-select-option value="应急电源">应急电源</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">电瓶</h3>
            <a-form-model-item label="型号" prop="battery_model">
              <a-input :disabled="showType=='detail'" v-model.trim="DCScreenParams.battery_model" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="battery_form">
              <a-select :disabled="showType=='detail'" v-model.trim="DCScreenParams.battery_form">
                <a-select-option value="免维护铅酸">免维护铅酸</a-select-option>
                <a-select-option value="普通铅酸">普通铅酸</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="battery_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="DCScreenParams.battery_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="battery_num">
              <a-input :disabled="showType=='detail'" v-model.trim="DCScreenParams.battery_num" />
            </a-form-model-item>
            <a-form-model-item label="运行方式" prop="battery_operation_mode">
              <a-select :disabled="showType=='detail'" v-model.trim="DCScreenParams.battery_operation_mode">
                <a-select-option value="浮充电">浮充电</a-select-option>
                <a-select-option value="充放电">充放电</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="额定容量（Ah）" prop="battery_rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="DCScreenParams.battery_rated_capacity" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="DCScreenParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================变压器==================== -->
          <div v-if="formData.equipment_param_type=='2'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="额定容量（KVA）" prop="rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.rated_capacity" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.rated_current" />
            </a-form-model-item>
            <a-form-model-item label="联接组标号" prop="connection_group_label">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.connection_group_label" />
            </a-form-model-item>
            <a-form-model-item label="短路阻抗（%）" prop="short_circuit_impedance">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.short_circuit_impedance" />
            </a-form-model-item>
            <a-form-model-item label="最高温升（K）" prop="maximum_temperature_rise">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.maximum_temperature_rise" />
            </a-form-model-item>
            <a-form-model-item label="冷却方式" prop="cooling_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_mode" />
            </a-form-model-item>
            <a-form-model-item label="使用条件" prop="conditions">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.conditions" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">冷却风机</h3>
            <a-form-model-item label="型号" prop="cooling_fan_model">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_model" />
            </a-form-model-item>
            <a-form-model-item label="风量（m³/h）" prop="cooling_fan_volume">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_volume" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="cooling_fan_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_speed" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="cooling_fan_num">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_num" />
            </a-form-model-item>
            <a-form-model-item label="功率（W）" prop="cooling_fan_power">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_power" />
            </a-form-model-item>
            <a-form-model-item label="相数" prop="cooling_fan_phase_num">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_phase_num" />
            </a-form-model-item>
            <a-form-model-item label="噪声" prop="cooling_fan_noise">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_noise" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="cooling_fan_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="cooling_fan_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.cooling_fan_rated_current" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">温度控制器</h3>
            <a-form-model-item label="型号" prop="temperature_controller_model">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.temperature_controller_model" />
            </a-form-model-item>
            <a-form-model-item label="测量范围" prop="temperature_controller_measuring_range">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.temperature_controller_measuring_range" />
            </a-form-model-item>
            <a-form-model-item label="传感器参数" prop="temperature_controller_sensor_param">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.temperature_controller_sensor_param" />
            </a-form-model-item>
            <a-form-model-item label="分辨率（℃）" prop="temperature_controller_resolving">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.temperature_controller_resolving" />
            </a-form-model-item>
            <a-form-model-item label="测量精度" prop="temperature_controller_measure_accuracy">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.temperature_controller_measure_accuracy" />
            </a-form-model-item>
            <a-form-model-item label="控制精度" prop="temperature_controller_control_accuracy">
              <a-input :disabled="showType=='detail'" v-model.trim="transformerParams.temperature_controller_control_accuracy" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="transformerParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================低压柜=================== -->
          <div v-if="formData.equipment_param_type=='3'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="电压等级（KV）" prop="voltage_level">
              <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.voltage_level" />
            </a-form-model-item>
            <a-form-model-item label="使用条件" prop="conditions">
              <a-select :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.conditions">
                <a-select-option value="户内">户内</a-select-option>
                <a-select-option value="户外">户外</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="内部结构" prop="internal_structure">
              <a-select :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.internal_structure">
                <a-select-option value="抽出式">抽出式</a-select-option>
                <a-select-option value="固定式">固定式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.function">
                <a-select-option value="进线">进线</a-select-option>
                <a-select-option value="出线">出线</a-select-option>
                <a-select-option value="市/发电转换">市/发电转换</a-select-option>
                <a-select-option value="联络">联络</a-select-option>
                <a-select-option value="动力">动力</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="进线回路数" prop="incoming_circuit_num">
              <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.incoming_circuit_num" />
            </a-form-model-item>
            <a-form-model-item label="进线方式" prop="incoming_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.incoming_mode" />
            </a-form-model-item>
            <a-form-model-item label="出线回路数" prop="outgoing_circuits_num">
              <a-select :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.outgoing_circuits_num"  @change="addLowVoltageCabinetParams(lowVoltageCabinetParams.outgoing_circuits_num)">
                <a-select-option v-for="i in 18" :key="i" :value="i">{{i+'个'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="出线方式" prop="outgoing_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.outgoing_mode" />
            </a-form-model-item>
            <a-form-model-item label="水平母线电流（A）" prop="horizontal_generator_current">
              <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.horizontal_generator_current" />
            </a-form-model-item>
            <a-form-model-item label="垂直母线电流（A）" prop="vertical_generator_current">
              <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.vertical_generator_current" />
            </a-form-model-item>
            <a-form-model-item label="母线形式" prop="generator_form">
              <a-select :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.generator_form">
                <a-select-option value="三相四线制">三相四线制</a-select-option>
                <a-select-option value="三相五线制">三相五线制</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <div v-for="i in lowVoltageCabinetParams.outgoing_circuits_num" :key="i">
              <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">{{'开关'+i}}</h3>
              <a-form-model-item label="型号" prop="switch_model">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].switch_model" />
              </a-form-model-item>
              <a-form-model-item label="额定电压（V）" prop="switch_rated_voltage">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].switch_rated_voltage" />
              </a-form-model-item>
              <a-form-model-item label="额定电流（A）" prop="switch_rated_current">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].switch_rated_current" />
              </a-form-model-item>
              <a-form-model-item label="形式" prop="switch_form">
                <a-select :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].switch_form">
                  <a-select-option value="框架式断路器">框架式断路器</a-select-option>
                  <a-select-option value="塑壳式断路器">塑壳式断路器</a-select-option>
                  <a-select-option value="小型断路器">小型断路器</a-select-option>
                  <a-select-option value="刀开关">刀开关</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="脱扣器电流（A）" prop="switch_release_current">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].switch_release_current" />
              </a-form-model-item>
              <a-form-model-item label="分断能力" prop="switch_breaking_capacity">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].switch_breaking_capacity" />
              </a-form-model-item>
<!--              <a-form-model-item label="供电范围" prop="switch_power_supply">-->
<!--                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].switch_power_supply" />-->
<!--              </a-form-model-item>-->
              <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">{{'电流互感器'+i}}</h3>
              <a-form-model-item label="型号" prop="current_transformer_model">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_model" />
              </a-form-model-item>
              <a-form-model-item label="额定容量（VA）" prop="current_transformer_rated_capacity">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_rated_capacity" />
              </a-form-model-item>
              <a-form-model-item label="电流变比" prop="current_transformer_ratio">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_ratio" />
              </a-form-model-item>
              <a-form-model-item label="数量" prop="current_transformer_num">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_num" />
              </a-form-model-item>
              <a-form-model-item label="额定电压（KV）" prop="current_transformer_voltage">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_voltage" />
              </a-form-model-item>
              <a-form-model-item label="一次额定电流（A）" prop="current_transformer_rated_current1">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_rated_current1" />
              </a-form-model-item>
              <a-form-model-item label="准确度等级" prop="current_transformer_accuracy_level">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_accuracy_level" />
              </a-form-model-item>
              <a-form-model-item label="二次额定电流（A）" prop="current_transformer_rated_current2">
                <a-input :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.affiliatedParams[i-1].current_transformer_rated_current2" />
              </a-form-model-item>
            </div>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="lowVoltageCabinetParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ==================电容补偿柜=================== -->
          <div v-if="formData.equipment_param_type=='4'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="电压等级（KV）" prop="voltage_level">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.voltage_level" />
            </a-form-model-item>
            <a-form-model-item label="使用条件" prop="conditions">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.conditions" />
            </a-form-model-item>
            <a-form-model-item label="内部结构" prop="internal_structure">
              <a-select :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.internal_structure">
                <a-select-option value="抽出式">抽出式</a-select-option>
                <a-select-option value="固定式">固定式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.power_factor" />
            </a-form-model-item>
            <a-form-model-item label="进线方式" prop="incoming_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.incoming_mode" />
            </a-form-model-item>
            <a-form-model-item label="出线方式" prop="outgoing_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.outgoing_mode" />
            </a-form-model-item>
            <a-form-model-item label="降温风机" prop="cooling_fan">
              <a-select :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.cooling_fan">
                <a-select-option value="无">无</a-select-option>
                <a-select-option value="有">有</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="残留电压" prop="residual_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.residual_voltage" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">开关</h3>
            <a-form-model-item label="型号" prop="switch_model">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.switch_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="switch_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.switch_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="操作方式" prop="operation_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.operation_mode" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="switch_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.switch_rated_current" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="switch_from">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.switch_from" />
            </a-form-model-item>
            <a-form-model-item label="熔体额定电流（A）" prop="switch_melt_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.switch_melt_rated_current" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">电流互感器</h3>
            <a-form-model-item label="型号" prop="current_transformer_model">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_model" />
            </a-form-model-item>
            <a-form-model-item label="额定容量（VA）" prop="current_transformer_rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_rated_capacity" />
            </a-form-model-item>
            <a-form-model-item label="电流变比" prop="current_transformer_ratio">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_ratio" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="current_transformer_num">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="current_transformer_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_voltage" />
            </a-form-model-item>
            <a-form-model-item label="一次额定电流（A）" prop="current_transformer_rated_current1">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_rated_current1" />
            </a-form-model-item>
            <a-form-model-item label="准确度等级" prop="current_transformer_accuracy_level">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_accuracy_level" />
            </a-form-model-item>
            <a-form-model-item label="二次额定电流（A）" prop="current_transformer_rated_current2">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_transformer_rated_current2" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">接触器</h3>
            <a-form-model-item label="型号" prop="contactor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.contactor_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="contactor_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.contactor_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="吸引线圈电压（V）" prop="suction_coil_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.suction_coil_voltage" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="contactor_num">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.contactor_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="contactor_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.contactor_rated_current" />
            </a-form-model-item>
            <a-form-model-item label="辅助触头数量" prop="auxiliary_contact_num">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.auxiliary_contact_num" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">热继电器</h3>
            <a-form-model-item label="型号" prop="thermal_relay_model">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.thermal_relay_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="thermal_relay_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.thermal_relay_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流调节范围（A）" prop="current_regulation_range">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.current_regulation_range" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="thermal_relay_num">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.thermal_relay_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="thermal_relay_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.thermal_relay_rated_current" />
            </a-form-model-item>
            <a-form-model-item label="复位方式" prop="reset_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.reset_mode" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">电容器</h3>
            <a-form-model-item label="型号" prop="capacitor_model">
                <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.capacitor_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="capacitor_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.capacitor_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定容量（Kvar）" prop="capacitor_rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.capacitor_rated_capacity" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="capacitor_num">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.capacitor_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="capacitor_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.capacitor_rated_current" />
            </a-form-model-item>
            <a-form-model-item label="接线方式" prop="connection_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.connection_mode" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">自动补偿仪</h3>
            <a-form-model-item label="型号" prop="automatic_compensator_model">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.automatic_compensator_model" />
            </a-form-model-item>
            <a-form-model-item label="控制回路数" prop="control_circuits_num">
              <a-input :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.control_circuits_num" />
            </a-form-model-item>
            <a-form-model-item label="温度控制" prop="temperature_control">
              <a-select :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.temperature_control">
                <a-select-option value="无">无</a-select-option>
                <a-select-option value="有">有</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="compensationCabinetParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ==================柴油发电机=================== -->
          <div v-if="formData.equipment_param_type=='5'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="发电机型号" prop="alternator_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.alternator_mode" />
            </a-form-model-item>
            <a-form-model-item label="发电机厂家" prop="alternator_manufacturer">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.alternator_manufacturer" />
            </a-form-model-item>
            <a-form-model-item label="常用功率" prop="normal_power">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.normal_power" />
            </a-form-model-item>
              <a-form-model-item label="备用功率" prop="spare_power">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.spare_power" />
            </a-form-model-item>
            <a-form-model-item label="柴油机型号" prop="diesel_engine_model">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.diesel_engine_model" />
            </a-form-model-item>
            <a-form-model-item label="柴油机厂家" prop="diesel_engine_manufacturer">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.diesel_engine_manufacturer" />
            </a-form-model-item>
            <a-form-model-item label="气缸数" prop="cylinder_num">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.cylinder_num" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.power_factor" />
            </a-form-model-item>
            <a-form-model-item label="转速" prop="speed">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.speed" />
            </a-form-model-item>
            <a-form-model-item label="电瓶电压" prop="battery_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.battery_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电瓶数量" prop="battery_number">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.battery_number" />
            </a-form-model-item>
            <a-form-model-item label="冷却方式" prop="cooling_mode">
              <a-select :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.cooling_mode">
                <a-select-option value="风冷却">风冷却</a-select-option>
                <a-select-option value="水冷却">水冷却</a-select-option>
                <a-select-option value="其它方式">其它方式</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">启动电瓶</h3>
            <a-form-model-item label="型号" prop="battery_model">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.battery_model" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="battery_form">
              <a-select :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.battery_form">
                <a-select-option value="免维护铅酸">免维护铅酸</a-select-option>
                <a-select-option value="普通铅酸">普通铅酸</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="battery_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.battery_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="battery_num">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.battery_num" />
            </a-form-model-item>
            <a-form-model-item label="运行方式" prop="operation_mode">
              <a-select :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.operation_mode">
                <a-select-option value="浮充电">浮充电</a-select-option>
                <a-select-option value="充放电">充放电</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="额定容量（Ah）" prop="battery_rated_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.battery_rated_capacity" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">燃油系统</h3>
            <a-form-model-item label="油箱容量" prop="tank_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.tank_capacity" />
            </a-form-model-item>
            <a-form-model-item label="油料型号" prop="oil_model">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.oil_model" />
            </a-form-model-item>
            <a-form-model-item label="加油口位置" prop="fuel_filler_position">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.fuel_filler_position" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">排烟系统</h3>
            <a-form-model-item label="处理方式" prop="handle_mode">
              <a-select :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.handle_mode">
                <a-select-option value="湿式水处理">湿式水处理</a-select-option>
                <a-select-option value="干式去黑烟处理">干式去黑烟处理</a-select-option>
                <a-select-option value="干式去有害物质处理">干式去有害物质处理</a-select-option>
                <a-select-option value="其它形式">其它形式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="排烟管道" prop="smoke_exhaust_pipe">
              <a-select :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.smoke_exhaust_pipe">
                <a-select-option value="管道式">管道式</a-select-option>
                <a-select-option value="砌筑式">砌筑式</a-select-option>
                <a-select-option value="混合式">混合式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="排烟口位置" prop="smoke_outlet_position">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.smoke_outlet_position" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">进风系统</h3>
            <a-form-model-item label="风机型号" prop="air_inlet_fan_model">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.air_inlet_fan_model" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="air_inlet_fan_num">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.air_inlet_fan_num" />
            </a-form-model-item>
            <a-form-model-item label="送风口位置" prop="air_inlet_position">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.air_outlet_position" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">排风系统</h3>
            <a-form-model-item label="风机型号" prop="exhaust_fan_model">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.exhaust_fan_model" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="exhaust_fan_num">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.exhaust_fan_num" />
            </a-form-model-item>
            <a-form-model-item label="排风口位置" prop="air_outlet_position">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.air_outlet_position" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">水冷系统</h3>
            <a-form-model-item label="冷却塔型号" prop="cooling_tower_model">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.cooling_tower_model" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="cooling_tower_num">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.cooling_tower_num" />
            </a-form-model-item>
            <a-form-model-item label="冷却塔位置" prop="cooling_tower_position">
              <a-input :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.cooling_tower_position" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================计算机=================== -->
          <div v-if="formData.equipment_param_type=='6'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="CPU型号" prop="CPU_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.CPU_mode" />
            </a-form-model-item>
            <a-form-model-item label="硬盘（G）" prop="hard_disk">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.hard_disk" />
            </a-form-model-item>
            <a-form-model-item label="内存（G）" prop="memory">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.memory" />
            </a-form-model-item>
            <a-form-model-item label="光驱" prop="CD_drive">
              <a-select :disabled="showType=='detail'" v-model.trim="computerParams.CD_drive">
                <a-select-option value="无">无</a-select-option>
                <a-select-option value="有">有</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="显示器（英寸）" prop="monitor">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.monitor" />
            </a-form-model-item>
            <a-form-model-item label="显示器接口" prop="monitor_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.monitor_interface" />
            </a-form-model-item>
            <a-form-model-item label="网卡" prop="network_card">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.network_card" />
            </a-form-model-item>
            <a-form-model-item label="网卡形式" prop="network_card_form">
              <a-select :disabled="showType=='detail'" v-model.trim="computerParams.network_card_form">
                <a-select-option value="铜缆">铜缆</a-select-option>
                <a-select-option value="光纤">光纤</a-select-option>
                <a-select-option value="wifi">wifi</a-select-option>
                <a-select-option value="上网卡">上网卡</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="操作系统" prop="operating_system">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.operating_system" />
            </a-form-model-item>
            <a-form-model-item label="主功能软件" prop="main_function_software">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.main_function_software" />
            </a-form-model-item>
            <a-form-model-item label="浏览器" prop="browser">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.browser" />
            </a-form-model-item>
            <a-form-model-item label="杀毒软件" prop="anti_virus_software">
              <a-input :disabled="showType=='detail'" v-model.trim="computerParams.anti_virus_software" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="computerParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================消防主机=================== -->
          <div v-if="formData.equipment_param_type=='7'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="最大带载数" prop="maximum_load_num">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.maximum_load_num" />
            </a-form-model-item>
            <a-form-model-item label="可带回路数" prop="with_loop_num">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.with_loop_num" />
            </a-form-model-item>
            <a-form-model-item label="回路可带载数" prop="loop_load_num">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.loop_load_num" />
            </a-form-model-item>
            <a-form-model-item label="通讯接口" prop="communication_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.communication_interface" />
            </a-form-model-item>
            <a-form-model-item label="安装负载数" prop="install_load_num">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.install_load_num" />
            </a-form-model-item>
            <a-form-model-item label="安装回路数" prop="install_loop_num">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.install_loop_num" />
            </a-form-model-item>
            <a-form-model-item label="报警输出" prop="alarm_output">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.alarm_output" />
            </a-form-model-item>
            <a-form-model-item label="可编程输出" prop="programmable_output">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.programmable_output" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">备用电瓶</h3>
            <a-form-model-item label="型号" prop="spare_battery_model">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.spare_battery_model" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="spare_battery_form">
              <a-select :disabled="showType=='detail'" v-model.trim="fireEngineParams.spare_battery_form">
                <a-select-option value="免维护铅酸">免维护铅酸</a-select-option>
                <a-select-option value="普通铅酸">普通铅酸</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="spare_battery_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.spare_battery_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="spare_battery_num">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.spare_battery_num" />
            </a-form-model-item>
            <a-form-model-item label="运行方式" prop="operation_mode">
              <a-select :disabled="showType=='detail'" v-model.trim="dieselGeneratorParams.operation_mode">
                <a-select-option value="浮充电">浮充电</a-select-option>
                <a-select-option value="充放电">充放电</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="额定容量（Ah）" prop="spare_battery_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.spare_battery_capacity" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">总线盘</h3>
            <a-form-model-item label="安装点数" prop="board_install_point">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.board_install_point" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="board_used_point">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.board_used_point" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">多线盘</h3>
            <a-form-model-item label="安装点数" prop="multi_wire_install_point">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.multi_wire_install_point" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="multi_wire_used_point">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.multi_wire_used_point" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">接入负载数</h3>
            <a-form-model-item label="烟感" prop="smoke_sensation">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.smoke_sensation" />
            </a-form-model-item>
            <a-form-model-item label="手动报警器" prop="manual_alarm">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.manual_alarm" />
            </a-form-model-item>
            <a-form-model-item label="水流开关" prop="water_flow_switch">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.water_flow_switch" />
            </a-form-model-item>
            <a-form-model-item label="温感" prop="warm_feeling">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.warm_feeling" />
            </a-form-model-item>
            <a-form-model-item label="消火栓按钮" prop="fire_hydrant_button">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.fire_hydrant_button" />
            </a-form-model-item>
            <a-form-model-item label="阀门状态" prop="valve_status">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.valve_status" />
            </a-form-model-item>
            <a-form-model-item label="火灾显示器" prop="fire_indicator">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.fire_indicator" />
            </a-form-model-item>
            <a-form-model-item label="手自动状态" prop="manual_auto_state">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.manual_auto_state" />
            </a-form-model-item>
            <a-form-model-item label="最不利点水压" prop="unfavourable_water_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.unfavourable_water_pressure" />
            </a-form-model-item>
            <a-form-model-item label="风机状态" prop="fan_state">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.fan_state" />
            </a-form-model-item>
            <a-form-model-item label="水泵状态" prop="water_pump_state">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.water_pump_state" />
            </a-form-model-item>
            <a-form-model-item label="防火卷帘状态" prop="fire_shutter_state">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.fire_shutter_state" />
            </a-form-model-item>
            <a-form-model-item label="风机控制" prop="fan_control">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.fan_control" />
            </a-form-model-item>
            <a-form-model-item label="水泵控制" prop="water_pump_control">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.water_pump_control" />
            </a-form-model-item>
            <a-form-model-item label="防火卷帘控制" prop="fire_shutter_control">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.fire_shutter_control" />
            </a-form-model-item>
            <a-form-model-item label="电梯迫降状态" prop="elevator_forced_landing_state">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.elevator_forced_landing_state" />
            </a-form-model-item>
            <a-form-model-item label="非消电源状态" prop="non_elimination_power_state">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.non_elimination_power_state" />
            </a-form-model-item>
            <a-form-model-item label="消防广播" prop="fire_broadcasting">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.fire_broadcasting" />
            </a-form-model-item>
            <a-form-model-item label="电梯迫降控制" prop="elevator_forced_landing_control">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.elevator_forced_landing_control" />
            </a-form-model-item>
            <a-form-model-item label="非消电源控制" prop="non_elimination_power_control">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.non_elimination_power_control" />
            </a-form-model-item>
            <a-form-model-item label="声光报警器" prop="audible_visual_alarm">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.audible_visual_alarm" />
            </a-form-model-item>
            <a-form-model-item label="其它" prop="other">
              <a-input :disabled="showType=='detail'" v-model.trim="fireEngineParams.other" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="fireEngineParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =================消防电话主机================== -->
          <div v-if="formData.equipment_param_type=='8'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="可装分机数" prop="installable_extension_num">
              <a-input :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.installable_extension_num" />
            </a-form-model-item>
            <a-form-model-item label="可装插孔数" prop="installable_jack_num">
              <a-input :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.installable_jack_num" />
            </a-form-model-item>
            <a-form-model-item label="工作电压" prop="work_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.work_voltage" />
            </a-form-model-item>
            <a-form-model-item label="通讯接口" prop="communication_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.communication_interface" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <a-form-model-item label="挂墙电话数量" prop="wall_hanging_phone_num">
              <a-input :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.wall_hanging_phone_num" />
            </a-form-model-item>
            <a-form-model-item label="插孔数量" prop="jack_num">
              <a-input :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.jack_num" />
            </a-form-model-item>
            <a-form-model-item label="插孔式电话数量" prop="jack_phone_num">
              <a-input :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.jack_phone_num" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="freTelephoneHostParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================广播功放=================== -->
          <div v-if="formData.equipment_param_type=='9'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.function">
                <a-select-option value="纯后级功放">纯后级功放</a-select-option>
                <a-select-option value="广播主机">广播主机</a-select-option>
                <a-select-option value="一体机">一体机</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="音频输入" prop="audio_input">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.audio_input" />
            </a-form-model-item>
            <a-form-model-item label="话筒输入" prop="mic_input">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.mic_input" />
            </a-form-model-item>
            <a-form-model-item label="网络接口" prop="network_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.network_interface" />
            </a-form-model-item>
            <a-form-model-item label="音频输出" prop="audio_output">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.audio_output" />
            </a-form-model-item>
            <a-form-model-item label="定压输出（V）" prop="constant_pressure_output">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.constant_pressure_output" />
            </a-form-model-item>
            <a-form-model-item label="定阻输出（Ω）" prop="fixed_resistance_output">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.fixed_resistance_output" />
            </a-form-model-item>
            <a-form-model-item label="输出功率（W）" prop="output_power">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.output_power" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">音箱</h3>
            <a-form-model-item label="数量" prop="loudspeaker_num">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.loudspeaker_num" />
            </a-form-model-item>
            <a-form-model-item label="输入（V）" prop="loudspeaker_input">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.loudspeaker_input" />
            </a-form-model-item>
            <a-form-model-item label="总功率（W）" prop="total_power">
              <a-input :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.total_power" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="radioAmplifierParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================视频矩阵=================== -->
          <div v-if="formData.equipment_param_type=='10'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="输入信号" prop="input_sign">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.input_sign" />
            </a-form-model-item>
            <a-form-model-item label="输出信号" prop="output_sign">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.output_sign" />
            </a-form-model-item>
            <a-form-model-item label="视频输入" prop="video_input">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.video_input" />
            </a-form-model-item>
            <a-form-model-item label="通讯输入" prop="communication_input">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.communication_input" />
            </a-form-model-item>
            <a-form-model-item label="输入端口" prop="input_port">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.input_port" />
            </a-form-model-item>
            <a-form-model-item label="输出端口" prop="output_port">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.output_port" />
            </a-form-model-item>
            <a-form-model-item label="视频输出" prop="video_output">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.video_output" />
            </a-form-model-item>
            <a-form-model-item label="通讯输出" prop="communication_output">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.communication_output" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">附属操作键盘</h3>
            <a-form-model-item label="数量" prop="keyboard_num">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.keyboard_num" />
            </a-form-model-item>
            <a-form-model-item label="通讯接口" prop="communication_port">
              <a-input :disabled="showType=='detail'" v-model.trim="videoMatrixParams.communication_port" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="videoMatrixParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ====================录像机=================== -->
          <div v-if="formData.equipment_param_type=='11'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">视频</h3>
            <a-form-model-item label="输入" prop="video_input">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.video_input" />
            </a-form-model-item>
            <a-form-model-item label="输出" prop="video_output">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.video_output" />
            </a-form-model-item>
            <a-form-model-item label="压缩格式" prop="video_compressed_format">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.video_compressed_format" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">音频</h3>
            <a-form-model-item label="输入" prop="audio_input">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.audio_input" />
            </a-form-model-item>
            <a-form-model-item label="输出" prop="audio_output">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.audio_output" />
            </a-form-model-item>
            <a-form-model-item label="压缩格式" prop="audio_compressed_format">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.audio_compressed_format" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">报警</h3>
            <a-form-model-item label="输入" prop="alarm_input">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.alarm_input" />
            </a-form-model-item>
            <a-form-model-item label="输出" prop="alarm_output">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.alarm_output" />
            </a-form-model-item>
            <a-form-model-item label="输出可编程" prop="alarm_compressed_format">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.alarm_compressed_format" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">硬盘</h3>
            <a-form-model-item label="输入" prop="hard_disk_input">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.hard_disk_input" />
            </a-form-model-item>
            <a-form-model-item label="输出" prop="hard_disk_output">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.hard_disk_output" />
            </a-form-model-item>
            <a-form-model-item label="支持硬盘" prop="hard_disk_compressed_format">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.hard_disk_compressed_format" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">接口</h3>
            <a-form-model-item label="网络接口" prop="network_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.network_interface" />
            </a-form-model-item>
            <a-form-model-item label="USB接口" prop="USB_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.USB_interface" />
            </a-form-model-item>
            <a-form-model-item label="通讯接口" prop="communication_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.communication_interface" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">录像</h3>
            <a-form-model-item label="录像/抓图" prop="videotape_snapshot">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.videotape_snapshot" />
            </a-form-model-item>
            <a-form-model-item label="备份方式" prop="backups_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.backups_mode" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">回放</h3>
            <a-form-model-item label="模式" prop="playback_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.lifting_height" />
            </a-form-model-item>
            <a-form-model-item label="同步回放" prop="sync_playback">
              <a-input :disabled="showType=='detail'" v-model.trim="videoRecorderParams.sync_playback" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="videoRecorderParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ====================模块箱=================== -->
          <div v-if="formData.equipment_param_type=='12'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">主控模块</h3>
            <a-form-model-item label="品牌" prop="main_control_brand1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_brand1" />
            </a-form-model-item>
            <a-form-model-item label="型号" prop="main_control_model1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_model1" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="main_control_num1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_num1" />
            </a-form-model-item>
            <a-form-model-item label="点数" prop="main_control_point1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_point1" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="main_control_used_point1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_used_point1" />
            </a-form-model-item>
            <div></div>
            <a-form-model-item label="品牌" prop="main_control_brand2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_brand2" />
            </a-form-model-item>
            <a-form-model-item label="型号" prop="main_control_model2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_model2" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="main_control_num2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_num2" />
            </a-form-model-item>
            <a-form-model-item label="点数" prop="main_control_point2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_point2" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="main_control_used_point2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.main_control_used_point2" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">扩展模块</h3>
            <a-form-model-item label="品牌" prop="extend_brand1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_brand1" />
            </a-form-model-item>
            <a-form-model-item label="型号" prop="extend_model1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_model1" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="extend_num1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_num1" />
            </a-form-model-item>
            <a-form-model-item label="点数" prop="extend_point1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_point1" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="extend_used_point1">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_used_point1" />
            </a-form-model-item>
            <div></div>
            <a-form-model-item label="品牌" prop="extend_brand2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_brand2" />
            </a-form-model-item>
            <a-form-model-item label="型号" prop="extend_model2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_model2" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="extend_num2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_num2" />
            </a-form-model-item>
            <a-form-model-item label="点数" prop="extend_point2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_point2" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="extend_used_point2">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_used_point2" />
            </a-form-model-item>
            <div></div>
            <a-form-model-item label="品牌" prop="extend_brand3">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_brand3" />
            </a-form-model-item>
            <a-form-model-item label="型号" prop="extend_model3">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_model3" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="extend_num3">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_num3" />
            </a-form-model-item>
            <a-form-model-item label="点数" prop="extend_point3">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_point3" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="extend_used_point3">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_used_point3" />
            </a-form-model-item>
            <div></div>
            <a-form-model-item label="品牌" prop="extend_brand4">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_brand4" />
            </a-form-model-item>
            <a-form-model-item label="型号" prop="extend_model4">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_model4" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="extend_num4">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_num4" />
            </a-form-model-item>
            <a-form-model-item label="点数" prop="extend_point4">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_point4" />
            </a-form-model-item>
            <a-form-model-item label="使用点数" prop="extend_used_point4">
              <a-input :disabled="showType=='detail'" v-model.trim="moduleBoxParams.extend_used_point4" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="moduleBoxParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =======================水泵================== -->
          <div v-if="formData.equipment_param_type=='13'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="泵体型号" prop="pump_model">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.pump_model" />
            </a-form-model-item>
            <a-form-model-item label="流量（m³/h）" prop="flow">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.flow" />
            </a-form-model-item>
            <a-form-model-item label="扬程（m）" prop="lift">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.lift" />
            </a-form-model-item>
            <a-form-model-item label="泵体序列号" prop="pump_serial_num">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.pump_serial_num" />
            </a-form-model-item>
            <a-form-model-item label="耐压" prop="withstand_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.withstand_voltage" />
            </a-form-model-item>
            <a-form-model-item label="必须气蚀（m）" prop="necessary_cavitation">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.necessary_cavitation" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">拖动电动机</h3>
            <a-form-model-item label="型号" prop="drive_motor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.drive_motor_model" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="drive_motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.drive_motor_power" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="drive_motor_power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.drive_motor_power_factor" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="drive_motor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="电压（V）" prop="drive_motor_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.drive_motor_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="drive_motor_current">
              <a-input :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.drive_motor_current" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="splitWaterPumpParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================一体水泵================== -->
          <div v-if="formData.equipment_param_type=='14'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="流量（m³/h）" prop="flow">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.flow" />
            </a-form-model-item>
            <a-form-model-item label="扬程（m）" prop="lift">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.lift" />
            </a-form-model-item>
            <a-form-model-item label="必须气蚀（m）" prop="necessary_cavitation">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.necessary_cavitation" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="power">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.power" />
            </a-form-model-item>
            <a-form-model-item label="电压（V）" prop="voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.voltage" />
            </a-form-model-item>
            <a-form-model-item label="频率（Hz）" prop="frequency">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.frequency" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.power_factor" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="current">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.current" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="speed">
              <a-input :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.speed" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="integratedWaterPumpParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================冷水机==================== -->
          <div v-if="formData.equipment_param_type=='15'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="制冷量" prop="refrigeration_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.refrigeration_capacity" />
            </a-form-model-item>
            <a-form-model-item label="输入功率（KW）" prop="input_power">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.input_power" />
            </a-form-model-item>
            <a-form-model-item label="输入电压（KV）" prop="input_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.input_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.rated_current" />
            </a-form-model-item>
            <a-form-model-item label="制冷剂" prop="refrigerant">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.refrigerant" />
            </a-form-model-item>
            <a-form-model-item label="压缩机形式" prop="compressor_form">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.compressor_form" />
            </a-form-model-item>
            <a-form-model-item label="起动形式" prop="start_form">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.start_form" />
            </a-form-model-item>
            <div></div>
            <a-form-model-item label="冷凝器形式" prop="condenser_form">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.condenser_form" />
            </a-form-model-item>
            <a-form-model-item label="冷凝器型号" prop="condenser_model">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.condenser_model" />
            </a-form-model-item>
            <a-form-model-item label="水侧工作压力（Mpa）" prop="condenser_water_side_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.condenser_water_side_pressure" />
            </a-form-model-item>
            <div></div>
            <a-form-model-item label="蒸发器形式" prop="evaporator_form">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.evaporator_form" />
            </a-form-model-item>
            <a-form-model-item label="蒸发器型号" prop="evaporator_model">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.evaporator_model" />
            </a-form-model-item>
            <a-form-model-item label="水侧工作压力（Mpa）" prop="evaporator_water_side_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.evaporator_water_side_pressure" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">启动柜</h3>
            <a-form-model-item label="型号" prop="starting_cabinet_model">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.starting_cabinet_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="starting_cabinet_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.starting_cabinet_voltage" />
            </a-form-model-item>
            <a-form-model-item label="工作耐受电压（KV）" prop="starting_cabinet_tolerance_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.starting_cabinet_tolerance_voltage" />
            </a-form-model-item>
            <a-form-model-item label="频率（Hz）" prop="starting_cabinet_frequency">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="starting_cabinet_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.starting_cabinet_rated_current" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="coolingWaterMachineParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================冷却塔==================== -->
          <div v-if="formData.equipment_param_type=='16'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="进水温度（℃）" prop="inlet_water_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.inlet_water_temperature" />
            </a-form-model-item>
            <a-form-model-item label="出水温度（℃）" prop="outlet_water_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.outlet_water_temperature" />
            </a-form-model-item>
            <a-form-model-item label="干球温度（℃）" prop="dry_bulb_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.dry_bulb_temperature" />
            </a-form-model-item>
            <a-form-model-item label="湿球温度（℃）" prop="wet_bulb_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.wet_bulb_temperature" />
            </a-form-model-item>
            <a-form-model-item label="冷却水量（m³/h）" prop="cooling_water_volume">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.cooling_water_volume" />
            </a-form-model-item>
            <a-form-model-item label="风量（m³/h）" prop="air_volume">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.air_volume" />
            </a-form-model-item>
            <a-form-model-item label="蒸发损失" prop="evaporation_loss">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.evaporation_loss" />
            </a-form-model-item>
            <a-form-model-item label="塔体扬程" prop="tower_head">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.tower_head" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">拖动电动机</h3>
            <a-form-model-item label="型号" prop="drive_motor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.drive_motor_model" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="drive_motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.drive_motor_power" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="drive_motor_power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.drive_motor_power_factor" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="drive_motor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="电压（V）" prop="drive_motor_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.drive_motor_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="drive_motor_current">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.drive_motor_current" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">风机</h3>
            <a-form-model-item label="风叶直径（mm）" prop="blade_diameter">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.blade_diameter" />
            </a-form-model-item>
            <a-form-model-item label="风叶数" prop="blade_num">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.blade_num" />
            </a-form-model-item>
            <a-form-model-item label="转速" prop="blade_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="coolingTowerParams.blade_speed" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="coolingTowerParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ====================热水锅炉=================== -->
          <div v-if="formData.equipment_param_type=='17'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="额定供热量（KW）" prop="rated_heat_supply">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.rated_heat_supply" />
            </a-form-model-item>
            <a-form-model-item label="额定出水温度（℃）" prop="rated_outlet_water_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.rated_outlet_water_temperature" />
            </a-form-model-item>
            <a-form-model-item label="额定回水温度（℃）" prop="rated_return_water_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.rated_return_water_temperature" />
            </a-form-model-item>
            <a-form-model-item label="燃料" prop="fuel">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.fuel" />
            </a-form-model-item>
            <a-form-model-item label="锅炉形式" prop="boiler_form">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.boiler_form" />
            </a-form-model-item>
            <a-form-model-item label="热效率（%）" prop="thermal_efficiency">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.thermal_efficiency" />
            </a-form-model-item>
            <a-form-model-item label="负荷调节范围" prop="load_regulation_range">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.load_regulation_range" />
            </a-form-model-item>
            <a-form-model-item label="排烟温度" prop="exhaust_gas_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.exhaust_gas_temperature" />
            </a-form-model-item>
            <a-form-model-item label="监控方式" prop="monitor_form">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.monitor_form" />
            </a-form-model-item>
            <a-form-model-item label="单台噪音" prop="single_set_noise">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.single_set_noise" />
            </a-form-model-item>
            <a-form-model-item label="锅炉水容量（㎡）" prop="boiler_water_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.boiler_water_capacity" />
            </a-form-model-item>
            <a-form-model-item label="传热面积（㎡）" prop="heat_transfer_area">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.heat_transfer_area" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">燃烧机</h3>
            <a-form-model-item label="型号" prop="combustion_engine_model">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.combustion_engine_model" />
            </a-form-model-item>
            <a-form-model-item label="功率范围" prop="power_range">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.power_range" />
            </a-form-model-item>
            <a-form-model-item label="电机功率（KW）" prop="motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.motor_power" />
            </a-form-model-item>
            <a-form-model-item label="产地" prop="combustion_engine_origin_place">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.combustion_engine_origin_place" />
            </a-form-model-item>
            <a-form-model-item label="额定压力" prop="rated_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.rated_pressure" />
            </a-form-model-item>
            <a-form-model-item label="使用电源" prop="used_power">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.used_power" />
            </a-form-model-item>
            <a-form-model-item label="调节方式" prop="adjust_form">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.adjust_form" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">锅炉控制器</h3>
            <a-form-model-item label="型号" prop="boiler_controller_model">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.boiler_controller_model" />
            </a-form-model-item>
            <a-form-model-item label="适配炉型" prop="suitable_furnace_type">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.suitable_furnace_type" />
            </a-form-model-item>
            <a-form-model-item label="产地" prop="boiler_controller_origin_place">
              <a-input :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.boiler_controller_origin_place" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="hotWaterBoilerParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================板式热交换器=============== -->
          <div v-if="formData.equipment_param_type=='18'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="热媒进出口温度（℃）" prop="heat_medium_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.heat_medium_temperature" />
            </a-form-model-item>
            <a-form-model-item label="热媒流量（t/h）" prop="heat_medium_flow">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.heat_medium_flow" />
            </a-form-model-item>
            <a-form-model-item label="允许压降（Kpa）" prop="heat_medium_pressure_drop">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.heat_medium_pressure_drop" />
            </a-form-model-item>
            <a-form-model-item label="冷媒进出口温度（℃）" prop="cool_medium_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.cool_medium_temperature" />
            </a-form-model-item>
            <a-form-model-item label="冷媒流量（t/h）" prop="cool_medium_flow">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.cool_medium_flow" />
            </a-form-model-item>
            <a-form-model-item label="允许压降（Kpa）" prop="cool_medium_pressure_drop">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.cool_medium_pressure_drop" />
            </a-form-model-item>
            <a-form-model-item label="热交换量（KW）" prop="heat_exchange_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.heat_exchange_capacity" />
            </a-form-model-item>
            <a-form-model-item label="换热面积（㎡）" prop="heat_exchange_area">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.heat_exchange_area" />
            </a-form-model-item>
            <a-form-model-item label="板片总量" prop="plate_total">
              <a-input :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.plate_total" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="plateHeatExchangerParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================空气处理机================ -->
          <div v-if="formData.equipment_param_type=='19'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="风机型号" prop="fan_model">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.fan_model" />
            </a-form-model-item>
            <a-form-model-item label="段数" prop="paragraph_num">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.paragraph_num" />
            </a-form-model-item>
            <a-form-model-item label="安装方式" prop="install_form">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.install_form" />
            </a-form-model-item>
            <a-form-model-item label="风阀数量" prop="air_valve_num">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.air_valve_num" />
            </a-form-model-item>
            <a-form-model-item label="风量（m³/h）" prop="air_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.air_capacity" />
            </a-form-model-item>
            <a-form-model-item label="制冷量" prop="refrigeration_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.refrigeration_capacity" />
            </a-form-model-item>
            <a-form-model-item label="制热量" prop="heating_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.heating_capacity" />
            </a-form-model-item>
            <a-form-model-item label="加(除)湿量" prop="humidification_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.humidification_capacity" />
            </a-form-model-item>
            <a-form-model-item label="机外静压(Pa)" prop="external_static_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.external_static_pressure" />
            </a-form-model-item>
            <a-form-model-item label="冷却盘管" prop="cooling_coil">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.cooling_coil" />
            </a-form-model-item>
            <a-form-model-item label="制热装置" prop="heating_device">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.heating_device" />
            </a-form-model-item>
            <a-form-model-item label="加(除)湿装置" prop="humidification_device">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.humidification_device" />
            </a-form-model-item>
            <a-form-model-item label="风机转速（rpm）" prop="fan_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.fan_speed" />
            </a-form-model-item>
            <a-form-model-item label="风机皮带轮" prop="fan_pulley">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.fan_pulley" />
            </a-form-model-item>
            <a-form-model-item label="电机皮带轮" prop="motor_pulley">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.motor_pulley" />
            </a-form-model-item>
            <a-form-model-item label="皮带" prop="belt">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.belt" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">拖动电动机</h3>
            <a-form-model-item label="型号" prop="drive_motor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.drive_motor_model" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="drive_motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.drive_motor_power" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="drive_motor_power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.drive_motor_power_factor" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="drive_motor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="电压（V）" prop="drive_motor_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.drive_motor_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="drive_motor_current">
              <a-input :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.drive_motor_current" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="airHandlingUnitParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================风机===================== -->
          <div v-if="formData.equipment_param_type=='20'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="风机型号" prop="fan_model">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.fan_model" />
            </a-form-model-item>
            <a-form-model-item label="风量（m³/h）" prop="air_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.air_capacity" />
            </a-form-model-item>
            <a-form-model-item label="静压（Pa）" prop="static_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.static_pressure" />
            </a-form-model-item>
            <a-form-model-item label="安装方式" prop="install_form">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.install_form" />
            </a-form-model-item>
            <a-form-model-item label="风机转速（rpm）" prop="fan_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.fan_speed" />
            </a-form-model-item>
            <a-form-model-item label="风机皮带轮" prop="fan_pulley">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.fan_pulley" />
            </a-form-model-item>
            <a-form-model-item label="电机皮带轮" prop="motor_pulley">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.motor_pulley" />
            </a-form-model-item>
            <a-form-model-item label="皮带" prop="belt">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.belt" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">拖动电动机</h3>
            <a-form-model-item label="型号" prop="drive_motor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.drive_motor_model" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="drive_motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.drive_motor_power" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="drive_motor_power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.drive_motor_power_factor" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="drive_motor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="电压（V）" prop="drive_motor_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.drive_motor_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="drive_motor_current">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.drive_motor_current" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">防火阀</h3>
            <a-form-model-item label="型号" prop="fire_damper_model">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.fire_damper_model" />
            </a-form-model-item>
            <a-form-model-item label="熔断温度（℃）" prop="fire_damper_fusing_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="fanParams.fire_damper_fusing_temperature" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="fanParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================曳引梯==================== -->
          <div v-if="formData.equipment_param_type=='21'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="轿厢" prop="lift_car">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.lift_car" />
            </a-form-model-item>
            <a-form-model-item label="乘客人数" prop="passenger_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.passenger_num" />
            </a-form-model-item>
            <a-form-model-item label="载重量（kg）" prop="dead_weight">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.dead_weight" />
            </a-form-model-item>
            <a-form-model-item label="速度（m/s）" prop="lift_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.lift_speed" />
            </a-form-model-item>
            <a-form-model-item label="层/站数" prop="stations_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.stations_num" />
            </a-form-model-item>
            <a-form-model-item label="厅门数" prop="door_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.door_num" />
            </a-form-model-item>
            <a-form-model-item label="停靠楼层" prop="docking_floor">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.docking_floor" />
            </a-form-model-item>
            <a-form-model-item label="提升高度（m）" prop="lifting_height">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.lifting_height" />
            </a-form-model-item>
            <a-form-model-item label="控制方式" prop="control_type">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.control_type" />
            </a-form-model-item>
            <a-form-model-item label="驱动系统" prop="drive_system">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.drive_system" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">曳引机</h3>
            <a-form-model-item label="型号" prop="traction_machine_model">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.traction_machine_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="traction_machine_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.traction_machine_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="traction_machine_current">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.traction_machine_current" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="traction_machine_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.traction_machine_num" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="traction_machine_power">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.traction_machine_power" />
            </a-form-model-item>
            <a-form-model-item label="传动比" prop="traction_machine_transmission_ratio">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.traction_machine_transmission_ratio" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">限速器</h3>
            <a-form-model-item label="型号" prop="speed_governor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.speed_governor_model" />
            </a-form-model-item>
            <a-form-model-item label="额定速度（m/s）" prop="speed_governor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.speed_governor_speed" />
            </a-form-model-item>
            <a-form-model-item label="机械动作速度（m/s）" prop="machine_action_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.speed_governor_action_speed" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="speed_governor_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.speed_governor_num" />
            </a-form-model-item>
            <a-form-model-item label="张紧力" prop="speed_governor_tension">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.speed_governor_tension" />
            </a-form-model-item>
            <a-form-model-item label="电气动作速度（m/s）" prop="electrical_action_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.electrical_action_speed" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">安全钳</h3>
            <a-form-model-item label="型号" prop="safety_gear_model">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.safety_gear_model" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="safety_gear_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.safety_gear_num" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="safety_gear_form">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.safety_gear_form" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">缓冲器</h3>
            <a-form-model-item label="型号" prop="buffer_model">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.buffer_model" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="buffer_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.buffer_num" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="buffer_form">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.buffer_form" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">变频器</h3>
            <a-form-model-item label="型号" prop="frequency_converter_model">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.frequency_converter_model" />
            </a-form-model-item>
            <a-form-model-item label="品牌" prop="frequency_converter_brand">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.frequency_converter_brand" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">门锁装置</h3>
            <a-form-model-item label="型号" prop="door_lock_device_model">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.door_lock_device_model" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">轿厢空调</h3>
            <a-form-model-item label="型号" prop="car_air_conditioner_model">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.car_air_conditioner_model" />
            </a-form-model-item>
            <a-form-model-item label="制冷量" prop="car_air_conditioner_refrigeration_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.car_air_conditioner_refrigeration_capacity" />
            </a-form-model-item>
            <a-form-model-item label="额定功率（W）" prop="car_air_conditioner_power">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.car_air_conditioner_power" />
            </a-form-model-item>
            <a-form-model-item label="品牌" prop="car_air_conditioner_brand">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.car_air_conditioner_brand" />
            </a-form-model-item>
            <a-form-model-item label="制冷剂" prop="car_air_conditioner_refrigerating_fluid">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.car_air_conditioner_refrigerating_fluid" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="car_air_conditioner_current">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.car_air_conditioner_current" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">井道安全门</h3>
            <a-form-model-item label="设置楼层" prop="shaft_safety_door_floor">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.shaft_safety_door_floor" />
            </a-form-model-item>
            <a-form-model-item label="数量" prop="shaft_safety_door_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.shaft_safety_door_num" />
            </a-form-model-item>
            <a-form-model-item label="门尺寸" prop="shaft_safety_door_size">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.shaft_safety_door_size" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="tractionLadderParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================液压梯==================== -->
          <div v-if="formData.equipment_param_type=='22'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="轿厢" prop="lift_car">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.lift_car" />
            </a-form-model-item>
            <a-form-model-item label="乘客人数" prop="passenger_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.passenger_num" />
            </a-form-model-item>
            <a-form-model-item label="载重量（Kg）" prop="dead_weight">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.dead_weight" />
            </a-form-model-item>
            <a-form-model-item label="速度（m/s）" prop="lift_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.lift_speed" />
            </a-form-model-item>
            <a-form-model-item label="层/站数" prop="stations_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.stations_num" />
            </a-form-model-item>
            <a-form-model-item label="厅门数" prop="door_num">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.door_num" />
            </a-form-model-item>
            <a-form-model-item label="停靠楼层" prop="docking_floor">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.docking_floor" />
            </a-form-model-item>
            <a-form-model-item label="提升高度（m）" prop="lifting_height">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.lifting_height" />
            </a-form-model-item>
            <a-form-model-item label="控制方式" prop="control_type">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.control_type" />
            </a-form-model-item>
            <a-form-model-item label="驱动系统" prop="drive_system">
              <a-input :disabled="showType=='detail'" v-model.trim="tractionLadderParams.drive_system" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">液压柱</h3>
            <a-form-model-item label="型号" prop="hydraulic_column_model">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.hydraulic_column_model" />
            </a-form-model-item>
            <a-form-model-item label="工作压力" prop="hydraulic_column_work_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.hydraulic_column_pressure" />
            </a-form-model-item>
            <a-form-model-item label="提升压力" prop="hydraulic_column_lift_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.hydraulic_column_lift_pressure" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="hydraulic_column_num">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.hydraulic_column_num" />
            </a-form-model-item>
            <a-form-model-item label="测试压力" prop="hydraulic_column_test_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.hydraulic_column_test_pressure" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">限速器</h3>
            <a-form-model-item label="型号" prop="speed_governor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.speed_governor_model" />
            </a-form-model-item>
            <a-form-model-item label="额定速度（m/s）" prop="speed_governor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.speed_governor_speed" />
            </a-form-model-item>
            <a-form-model-item label="机械动作速度（m/s）" prop="machine_action_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.speed_governor_action_speed" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="speed_governor_num">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.speed_governor_num" />
            </a-form-model-item>
            <a-form-model-item label="张紧力" prop="speed_governor_tension">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.speed_governor_tension" />
            </a-form-model-item>
            <a-form-model-item label="电气动作速度（m/s）" prop="electrical_action_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.electrical_action_speed" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">门锁装置</h3>
            <a-form-model-item label="型号" prop="door_lock_device_model">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.door_lock_device_model" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">轿厢空调</h3>
            <a-form-model-item label="型号" prop="car_air_conditioner_model">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.car_air_conditioner_model" />
            </a-form-model-item>
            <a-form-model-item label="制冷量" prop="car_air_conditioner_refrigeration_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.car_air_conditioner_refrigeration_capacity" />
            </a-form-model-item>
            <a-form-model-item label="额定功率（W）" prop="car_air_conditioner_power">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.car_air_conditioner_power" />
            </a-form-model-item>
            <a-form-model-item label="品牌" prop="car_air_conditioner_brand">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.car_air_conditioner_brand" />
            </a-form-model-item>
            <a-form-model-item label="制冷剂" prop="car_air_conditioner_refrigerating_fluid">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.car_air_conditioner_refrigerating_fluid" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="car_air_conditioner_current">
              <a-input :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.car_air_conditioner_current" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="hydraulicLadderParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================扶梯===================== -->
          <div v-if="formData.equipment_param_type=='23'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="提升高度（mm）" prop="lifting_height">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.lifting_height" />
            </a-form-model-item>
            <a-form-model-item label="倾斜角度" prop="tilt_angle">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.tilt_angle" />
            </a-form-model-item>
            <a-form-model-item label="梯级宽度（mm）" prop="step_width">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.step_width" />
            </a-form-model-item>
            <a-form-model-item label="牵引链节距（mm）" prop="traction_chain_pitch">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.traction_chain_pitch" />
            </a-form-model-item>
            <a-form-model-item label="运输能力（人/小时）" prop="transportation_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.transportation_capacity" />
            </a-form-model-item>
            <a-form-model-item label="速度（m/s）" prop="speed">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.speed" />
            </a-form-model-item>
            <a-form-model-item label="控制型式" prop="control_type">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.control_type" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">拖动电动机</h3>
            <a-form-model-item label="型号" prop="drive_motor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_motor_model" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="drive_motor_num">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_motor_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="drive_motor_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_motor_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="drive_motor_electricity">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_motor_electricity" />
            </a-form-model-item>
            <a-form-model-item label="额定转速（rpm）" prop="drive_motor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="绝缘等级" prop="drive_motor_insulation">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_motor_insulation" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">驱动主机</h3>
            <a-form-model-item label="型号" prop="drive_host_model">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_host_model" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="drive_host_num">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_host_num" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="drive_host_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_host_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="drive_host_electricity">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_host_electricity" />
            </a-form-model-item>
            <a-form-model-item label="传动方式" prop="drive_host_transmission_mode">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_host_transmission_mode" />
            </a-form-model-item>
            <a-form-model-item label="传动比" prop="drive_host_transmission_ratio">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.drive_host_transmission_ratio" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">制动器</h3>
            <a-form-model-item label="型号" prop="brake_model">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.brake_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（V）" prop="brake_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.brake_voltage" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">控制屏</h3>
            <a-form-model-item label="型号" prop="control_panel_model">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.control_panel_model" />
            </a-form-model-item>
            <a-form-model-item label="编号" prop="control_panel_num">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.control_panel_num" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">变频器</h3>
            <a-form-model-item label="名称" prop="transducer_model">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.transducer_model" />
            </a-form-model-item>
            <a-form-model-item label="品牌" prop="transducer_brand">
              <a-input :disabled="showType=='detail'" v-model.trim="escalatorParams.transducer_brand" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="escalatorParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================擦窗机===================== -->
          <div v-if="formData.equipment_param_type=='24'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="形式" prop="window_cleaner_form">
              <a-select :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_form">
                <a-select-option value="水平轨道式">水平轨道式</a-select-option>
                <a-select-option value="附墙轨道式">附墙轨道式</a-select-option>
                <a-select-option value="悬挂轨道式">悬挂轨道式</a-select-option>
                <a-select-option value="插杆式">插杆式</a-select-option>
                <a-select-option value="滑梯式">滑梯式</a-select-option>
                <a-select-option value="轮载式">轮载式</a-select-option>
                <a-select-option value="其它形式">其它形式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="额定载荷（kg）" prop="window_cleaner_rated_load">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_rated_load" />
            </a-form-model-item>
            <a-form-model-item label="变幅距离（m）" prop="window_cleaner_luffing_distance">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_luffing_distance" />
            </a-form-model-item>
            <a-form-model-item label="伸缩高度（m）" prop="window_cleaner_telescoping_height">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_telescoping_height" />
            </a-form-model-item>
            <a-form-model-item label="升降速度（m/min）" prop="window_cleaner_lifting_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_lifting_speed" />
            </a-form-model-item>
            <a-form-model-item label="行走速度（m/min）" prop="window_cleaner_walk_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_walk_speed" />
            </a-form-model-item>
            <a-form-model-item label="变幅速度（m/min）" prop="window_cleaner_luffing_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_luffing_speed" />
            </a-form-model-item>
            <a-form-model-item label="回旋速度（m/min）" prop="window_cleaner_swing_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_swing_speed" />
            </a-form-model-item>
            <a-form-model-item label="限载人数" prop="window_cleaner_limited_load_num">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_limited_load_num" />
            </a-form-model-item>
            <a-form-model-item label="吊篮尺寸" prop="window_cleaner_basket_size">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_basket_size" />
            </a-form-model-item>
            <a-form-model-item label="电机功率" prop="window_cleaner_motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.window_cleaner_motor_power" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">钢丝绳</h3>
            <a-form-model-item label="型号" prop="steel_wire_rope_model">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.steel_wire_rope_model" />
            </a-form-model-item>
            <a-form-model-item label="规格" prop="steel_wire_rope_specifications">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.steel_wire_rope_specifications" />
            </a-form-model-item>
            <a-form-model-item label="长度" prop="steel_wire_rope_length">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.steel_wire_rope_length" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">安全绳</h3>
            <a-form-model-item label="型号" prop="safety_rope_model">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.safety_rope_model" />
            </a-form-model-item>
            <a-form-model-item label="规格" prop="safety_rope_specifications">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.safety_rope_specifications" />
            </a-form-model-item>
            <a-form-model-item label="长度" prop="safety_rope_length">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.safety_rope_length" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">随行电缆</h3>
            <a-form-model-item label="型号" prop="traveling_cable_model">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.traveling_cable_model" />
            </a-form-model-item>
            <a-form-model-item label="规格" prop="traveling_cable_specifications">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.traveling_cable_specifications" />
            </a-form-model-item>
            <a-form-model-item label="长度" prop="traveling_cable_length">
              <a-input :disabled="showType=='detail'" v-model.trim="windowCleanerParams.traveling_cable_length" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="windowCleanerParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================烟感===================== -->
          <div v-if="formData.equipment_param_type=='25'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="形式" prop="smoke_sensation_type">
              <a-select :disabled="showType=='detail'" v-model.trim="smokeSensationParams.smoke_sensation_type">
                <a-select-option value="点型离子">点型离子</a-select-option>
                <a-select-option value="点型广电">点型广电</a-select-option>
                <a-select-option value="线型光束">线型光束</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="供电方式" prop="smoke_sensation_power_type">
              <a-select :disabled="showType=='detail'" v-model.trim="smokeSensationParams.smoke_sensation_power_type">
                <a-select-option value="总线24">总线24</a-select-option>
                <a-select-option value="DC24">DC24</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="编码方式" prop="smoke_sensation_code_type">
              <a-select :disabled="showType=='detail'" v-model.trim="smokeSensationParams.smoke_sensation_code_type">
                <a-select-option value="可编址">可编址</a-select-option>
                <a-select-option value="不可编址">不可编址</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="smokeSensationParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================温感===================== -->
          <div v-if="formData.equipment_param_type=='26'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="形式" prop="warm_feeling_type">
              <a-select :disabled="showType=='detail'" v-model.trim="warmFeelingParams.warm_feeling_type">
                <a-select-option value="点型定温">点型定温</a-select-option>
                <a-select-option value="点型差温">点型差温</a-select-option>
                <a-select-option value="点型差定温">点型差定温</a-select-option>
                <a-select-option value="线型定温">线型定温</a-select-option>
                <a-select-option value="线型差温">线型差温</a-select-option>
                <a-select-option value="线型差定温">线型差定温</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="供电方式" prop="warm_feeling_power_type">
              <a-select :disabled="showType=='detail'" v-model.trim="warmFeelingParams.warm_feeling_power_type">
                <a-select-option value="总线24">总线24</a-select-option>
                <a-select-option value="DC24">DC24</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="编码方式" prop="warm_feeling_code_type">
              <a-select :disabled="showType=='detail'" v-model.trim="warmFeelingParams.warm_feeling_code_type">
                <a-select-option value="可编址">可编址</a-select-option>
                <a-select-option value="不可编址">不可编址</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="产品类别" prop="warm_feeling_product_category">
              <a-select :disabled="showType=='detail'" v-model.trim="warmFeelingParams.warm_feeling_product_category">
                <a-select-option value="A1">A1</a-select-option>
                <a-select-option value="A1R">A1R</a-select-option>
                <a-select-option value="A2">A2</a-select-option>
                <a-select-option value="A2R">A2R</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="warmFeelingParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================破玻按钮================== -->
          <div v-if="formData.equipment_param_type=='27'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="输出形式" prop="breaking_glass_type">
              <a-select :disabled="showType=='detail'" v-model.trim="breakingGlassParams.breaking_glass_type">
                <a-select-option value="单路触电">单路触电</a-select-option>
                <a-select-option value="双路触电">双路触电</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="供电方式" prop="breaking_glass_power_type">
              <a-select :disabled="showType=='detail'" v-model.trim="breakingGlassParams.breaking_glass_power_type">
                <a-select-option value="总线24">总线24</a-select-option>
                <a-select-option value="DC24">DC24</a-select-option>
                <a-select-option value="无">无</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="编码方式" prop="breaking_glass_code_type">
              <a-select :disabled="showType=='detail'" v-model.trim="breakingGlassParams.breaking_glass_code_type">
                <a-select-option value="可编址">可编址</a-select-option>
                <a-select-option value="不可编址">不可编址</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="复位方式" prop="breaking_glass_reset_type">
              <a-select :disabled="showType=='detail'" v-model.trim="breakingGlassParams.breaking_glass_reset_type">
                <a-select-option value="手动">手动</a-select-option>
                <a-select-option value="不可">不可</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="breakingGlassParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================消防模块================== -->
          <div v-if="formData.equipment_param_type=='28'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="模块类型" prop="fire_module_type">
              <a-select :disabled="showType=='detail'" v-model.trim="fireModuleParams.fire_module_type">
                <a-select-option value="输入模块">输入模块</a-select-option>
                <a-select-option value="输出模块">输出模块</a-select-option>
                <a-select-option value="输入输出模块">输入输出模块</a-select-option>
                <a-select-option value="中继模块">中继模块</a-select-option>
                <a-select-option value="隔离模块">隔离模块</a-select-option>
                <a-select-option value="切换模块">切换模块</a-select-option>
                <a-select-option value="多线模块">多线模块</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="供电方式" prop="fire_module_power_type">
              <a-select :disabled="showType=='detail'" v-model.trim="fireModuleParams.fire_module_power_type">
                <a-select-option value="总线24">总线24</a-select-option>
                <a-select-option value="DC24">DC24</a-select-option>
                <a-select-option value="无">无</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="带点数" prop="fire_module_point_num">
              <a-input :disabled="showType=='detail'" v-model.trim="fireModuleParams.fire_module_point_num" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="fireModuleParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================消防分机===================== -->
          <div v-if="formData.equipment_param_type=='29'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="分机形式" prop="fire_extension_type">
              <a-select :disabled="showType=='detail'" v-model.trim="fireExtensionParams.fire_extension_type">
                <a-select-option value="电话分机">电话分机</a-select-option>
                <a-select-option value="电话插孔">电话插孔</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="供电方式" prop="fire_extension_power_type">
              <a-select :disabled="showType=='detail'" v-model.trim="fireExtensionParams.fire_extension_power_type">
                <a-select-option value="总线24">总线24</a-select-option>
                <a-select-option value="DC24">DC24</a-select-option>
                <a-select-option value="无">无</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="编码方式" prop="fire_extension_code_type">
              <a-select :disabled="showType=='detail'" v-model.trim="fireExtensionParams.fire_extension_code_type">
                <a-select-option value="可编址">可编址</a-select-option>
                <a-select-option value="不可编址">不可编址</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="系统类型" prop="fire_extension_system_type">
              <a-select :disabled="showType=='detail'" v-model.trim="fireExtensionParams.fire_extension_system_type">
                <a-select-option value="共电">共电</a-select-option>
                <a-select-option value="对讲">对讲</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="fireExtensionParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================摄像机===================== -->
          <div v-if="formData.equipment_param_type=='30'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="形式" prop="video_camera_type">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_type">
                <a-select-option value="枪机">枪机</a-select-option>
                <a-select-option value="半球">半球</a-select-option>
                <a-select-option value="球机">球机</a-select-option>
                <a-select-option value="云台">云台</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="供电方式" prop="video_camera_power_type">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_power_type">
                <a-select-option value="独立供电">独立供电</a-select-option>
                <a-select-option value="POE">POE</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="视频输出接口" prop="video_camera_output_interface">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_output_interface">
                <a-select-option value="BNC">BNC</a-select-option>
                <a-select-option value="RJ45">RJ45</a-select-option>
                <a-select-option value="POE">POE</a-select-option>
                <a-select-option value="无线">无线</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="感光元件尺寸" prop="video_camera_photosensitive_size">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_photosensitive_size">
                <a-select-option value="1/3''">1/3"</a-select-option>
                <a-select-option value="1/4''">1/4"</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="焦距" prop="video_camera_focal_length">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_focal_length">
                <a-select-option value="2.8">2.8</a-select-option>
                <a-select-option value="3.6">3.6</a-select-option>
                <a-select-option value="4">4</a-select-option>
                <a-select-option value="6">6</a-select-option>
                <a-select-option value="8">8</a-select-option>
                <a-select-option value="12">12</a-select-option>
                <a-select-option value="16">16</a-select-option>
                <a-select-option value="25">25</a-select-option>
                <a-select-option value="60">60</a-select-option>
                <a-select-option value="变焦">变焦</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="分辨率" prop="video_camera_resolving_power">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_resolving_power">
                <a-select-option value="720P">720P</a-select-option>
                <a-select-option value="960P">960P</a-select-option>
                <a-select-option value="1080P">1080P</a-select-option>
                <a-select-option value="4K">4K</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="补光灯" prop="video_camera_fill_in_light">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_fill_in_light">
                <a-select-option value="有">有</a-select-option>
                <a-select-option value="无">无</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="安装位置" prop="video_camera_address">
              <a-select :disabled="showType=='detail'" v-model.trim="videoCameraParams.video_camera_address">
                <a-select-option value="室内">室内</a-select-option>
                <a-select-option value="室外">室外</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="videoCameraParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================红外探头===================== -->
          <div v-if="formData.equipment_param_type=='31'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="探头形式" prop="infrared_probe_type">
              <a-select :disabled="showType=='detail'" v-model.trim="infraredProbeParams.infrared_probe_type">
                <a-select-option value="被动式">被动式</a-select-option>
                <a-select-option value="主动式">主动式</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="工作电压" prop="infrared_probe_working_voltage">
              <a-select :disabled="showType=='detail'" v-model.trim="infraredProbeParams.infrared_probe_working_voltage">
                <a-select-option value="DC12V">DC12V</a-select-option>
                <a-select-option value="DC24V">DC24V</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="探测形式" prop="infrared_probe_form">
              <a-select :disabled="showType=='detail'" v-model.trim="infraredProbeParams.infrared_probe_form">
                <a-select-option value="单红外">单红外</a-select-option>
                <a-select-option value="红外+微波">红外+微波</a-select-option>
                <a-select-option value="红外+微波+智能处理">红外+微波+智能处理</a-select-option>
                <a-select-option value="单光束">单光束</a-select-option>
                <a-select-option value="双光束">双光束</a-select-option>
                <a-select-option value="三光束">三光束</a-select-option>
                <a-select-option value="四光束">四光束</a-select-option>
                <a-select-option value="八光束">八光束</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="探测距离" prop="infrared_probe_distance">
              <a-input :disabled="showType=='detail'" v-model.trim="infraredProbeParams.infrared_probe_distance" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="infraredProbeParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ================配电（控制）箱（柜）================ -->
          <div v-if="formData.equipment_param_type=='32'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="使用条件" prop="conditions">
              <a-select :disabled="showType=='detail'" v-model.trim="powerDistributionParams.conditions">
                <a-select-option value="被动式">被动式</a-select-option>
                <a-select-option value="主动式">主动式</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="powerDistributionParams.function">
                <a-select-option value="配电">配电</a-select-option>
                <a-select-option value="控制">控制</a-select-option>
                <a-select-option value="双电源切换">双电源切换</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="进线回路数" prop="incoming_circuit_num">
              <a-select :disabled="showType=='detail'" v-model.trim="powerDistributionParams.incoming_circuit_num">
                <a-select-option value="1">1</a-select-option>
                <a-select-option value="2">2</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="出线回路数" prop="outgoing_circuit_num">
              <a-select :disabled="showType=='detail'" v-model.trim="powerDistributionParams.outgoing_circuit_num" @change="addPowerDistributionParams(powerDistributionParams.outgoing_circuit_num)">
                <a-select-option v-for="i in 8" :key="i" :value="i">{{i+'个'}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">主开关1</h3>
            <a-form-model-item label="型号" prop="switch1_model">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch1_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="switch1_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch1_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="switch1_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch1_rated_current" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="switch1_form">
              <a-select :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch1_form">
                <a-select-option value="塑壳式断路器">塑壳式断路器</a-select-option>
                <a-select-option value="小型断路器">小型断路器</a-select-option>
                <a-select-option value="交流接触器">交流接触器</a-select-option>
                <a-select-option value="直流接触器">直流接触器</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">主开关2</h3>
            <a-form-model-item label="型号" prop="switch2_model">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch2_model" />
            </a-form-model-item>
            <a-form-model-item label="额定电压（KV）" prop="switch2_rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch2_rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流（A）" prop="switch2_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch2_rated_current" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="switch2_form">
              <a-select :disabled="showType=='detail'" v-model.trim="powerDistributionParams.switch2_form">
                <a-select-option value="塑壳式断路器">塑壳式断路器</a-select-option>
                <a-select-option value="小型断路器">小型断路器</a-select-option>
                <a-select-option value="交流接触器">交流接触器</a-select-option>
                <a-select-option value="直流接触器">直流接触器</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">控制器</h3>
            <a-form-model-item label="型号" prop="controller_model">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.controller_model" />
            </a-form-model-item>
            <a-form-model-item label="类型" prop="controller_type">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.controller_type" />
            </a-form-model-item>
            <a-form-model-item label="作用" prop="controller_effect">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.controller_effect" />
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">变频器</h3>
            <a-form-model-item label="品牌" prop="converter_brand">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.converter_brand" />
            </a-form-model-item>
            <a-form-model-item label="类型" prop="converter_model">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.converter_model" />
            </a-form-model-item>
            <a-form-model-item label="作用" prop="converter_effect">
              <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.converter_effect" />
            </a-form-model-item>
            <div v-for="i in powerDistributionParams.outgoing_circuit_num" :key="i">
              <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">{{'开关/接触器'+i}}</h3>
              <a-form-model-item label="型号" prop="contactor_model">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].contactor_model" />
              </a-form-model-item>
              <a-form-model-item label="额定电压（V）" prop="contactor_rated_voltage">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].contactor_rated_voltage" />
              </a-form-model-item>
              <a-form-model-item label="额定电流（A）" prop="contactor_rated_current">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].contactor_rated_current" />
              </a-form-model-item>
              <a-form-model-item label="形式" prop="contactor_form">
                <a-select :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].contactor_form">
                  <a-select-option value="塑壳式断路器">塑壳式断路器</a-select-option>
                  <a-select-option value="小型断路器">小型断路器</a-select-option>
                  <a-select-option value="交流接触器">交流接触器</a-select-option>
                  <a-select-option value="直流接触器">直流接触器</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="吸引线圈电压（V）" prop="suction_coil_voltage">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].suction_coil_voltage" />
              </a-form-model-item>
              <a-form-model-item label="辅助触头数量" prop="auxiliary_contact_num">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].auxiliary_contact_num" />
              </a-form-model-item>
              <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">{{'热继电器'+i}}</h3>
              <a-form-model-item label="型号" prop="thermal_relay_model">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].thermal_relay_model" />
              </a-form-model-item>
              <a-form-model-item label="额定电压（V）" prop="thermal_relay_rated_voltage">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].thermal_relay_rated_voltage" />
              </a-form-model-item>
              <a-form-model-item label="电流调节范围（A）" prop="current_regulation_range">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_regulation_range" />
              </a-form-model-item>
              <a-form-model-item label="数量" prop="thermal_relay_num">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].thermal_relay_num" />
              </a-form-model-item>
              <a-form-model-item label="额定电流（A）" prop="thermal_relay_rated_current">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].thermal_relay_rated_current" />
              </a-form-model-item>
              <a-form-model-item label="复位方式" prop="reset_mode">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].reset_mode" />
              </a-form-model-item>
              <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">{{'电流互感器'+i}}</h3>
              <a-form-model-item label="型号" prop="current_transformer_model">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_model" />
              </a-form-model-item>
              <a-form-model-item label="额定容量（VA）" prop="current_transformer_rated_capacity">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_rated_capacity" />
              </a-form-model-item>
              <a-form-model-item label="电流变比" prop="current_transformer_ratio">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_ratio" />
              </a-form-model-item>
              <a-form-model-item label="数量" prop="current_transformer_num">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_num" />
              </a-form-model-item>
              <a-form-model-item label="额定电压（KV）" prop="current_transformer_voltage">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_voltage" />
              </a-form-model-item>
              <a-form-model-item label="一次额定电流（A）" prop="current_transformer_rated_current1">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_rated_current1" />
              </a-form-model-item>
              <a-form-model-item label="准确度等级" prop="current_transformer_accuracy_level">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_accuracy_level" />
              </a-form-model-item>
              <a-form-model-item label="二次额定电流（A）" prop="current_transformer_rated_current2">
                <a-input :disabled="showType=='detail'" v-model.trim="powerDistributionParams.affiliatedParams[i-1].current_transformer_rated_current2" />
              </a-form-model-item>
            </div>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="powerDistributionParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================灯具====================== -->
          <div v-if="formData.equipment_param_type=='33'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="额定电压" prop="rated_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="luminairesParams.rated_voltage" />
            </a-form-model-item>
            <a-form-model-item label="额定电流" prop="rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="luminairesParams.rated_current" />
            </a-form-model-item>
            <a-form-model-item label="额定功率" prop="conditioner_power">
              <a-input :disabled="showType=='detail'" v-model.trim="luminairesParams.conditioner_power" />
            </a-form-model-item>
            <a-form-model-item label="色温" prop="color_temperature">
              <a-input :disabled="showType=='detail'" v-model.trim="luminairesParams.color_temperature" />
            </a-form-model-item>
            <a-form-model-item label="安装方式" prop="install_mode">
              <a-select :disabled="showType=='detail'" v-model.trim="luminairesParams.install_mode">
                <a-select-option value="吸（嵌）顶">吸（嵌）顶</a-select-option>
                <a-select-option value="吊挂">吊挂</a-select-option>
                <a-select-option value="壁挂">壁挂</a-select-option>
                <a-select-option value="高杆">高杆</a-select-option>
                <a-select-option value="地埋">地埋</a-select-option>
                <a-select-option value="水下">水下</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="控制方式" prop="controller_mode">
              <a-select :disabled="showType=='detail'" v-model.trim="luminairesParams.controller_mode">
                <a-select-option value="手动">手动</a-select-option>
                <a-select-option value="定时器">定时器</a-select-option>
                <a-select-option value="系统控制">系统控制</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="luminairesParams.function">
                <a-select-option value="公共照明">公共照明</a-select-option>
                <a-select-option value="园林照明">园林照明</a-select-option>
                <a-select-option value="泛光照明">泛光照明</a-select-option>
                <a-select-option value="场景照明">场景照明</a-select-option>
                <a-select-option value="LOGO灯">LOGO灯</a-select-option>
                <a-select-option value="航空障碍灯">航空障碍灯</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="luminairesParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================管道设施====================== -->
          <div v-if="formData.equipment_param_type=='34'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="管径" prop="diameter">
              <a-select :disabled="showType=='detail'" v-model.trim="pipelineParams.diameter">
                <a-select-option value="DN8">DN8</a-select-option>
                <a-select-option value="DN10">DN10</a-select-option>
                <a-select-option value="DN15">DN15</a-select-option>
                <a-select-option value="DN20">DN20</a-select-option>
                <a-select-option value="DN25">DN25</a-select-option>
                <a-select-option value="DN32">DN32</a-select-option>
                <a-select-option value="DN40">DN40</a-select-option>
                <a-select-option value="DN50">DN50</a-select-option>
                <a-select-option value="DN65">DN65</a-select-option>
                <a-select-option value="DN80">DN80</a-select-option>
                <a-select-option value="DN100">DN100</a-select-option>
                <a-select-option value="DN125">DN125</a-select-option>
                <a-select-option value="DN150">DN150</a-select-option>
                <a-select-option value="DN200">DN200</a-select-option>
                <a-select-option value="DN250">DN250</a-select-option>
                <a-select-option value="DN300">DN300</a-select-option>
                <a-select-option value="DN350">DN350</a-select-option>
                <a-select-option value="DN400">DN400</a-select-option>
                <a-select-option value="DN450">DN450</a-select-option>
                <a-select-option value="DN500">DN500</a-select-option>
                <a-select-option value="DN600">DN600</a-select-option>
                <a-select-option value="DN700">DN700</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="工作压力（Mpa）" prop="working_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="pipelineParams.working_pressure" />
            </a-form-model-item>
            <a-form-model-item label="连接方法" prop="connection_method">
              <a-select :disabled="showType=='detail'" v-model.trim="pipelineParams.connection_method">
                <a-select-option value="螺纹">螺纹</a-select-option>
                <a-select-option value="法兰">法兰</a-select-option>
                <a-select-option value="卡箍">卡箍</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="驱动方式" prop="driving_method">
              <a-select :disabled="showType=='detail'" v-model.trim="pipelineParams.driving_method">
                <a-select-option value="手动">手动</a-select-option>
                <a-select-option value="电动">电动</a-select-option>
                <a-select-option value="调节">调节</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="反馈方式" prop="feedback_method">
              <a-select :disabled="showType=='detail'" v-model.trim="pipelineParams.feedback_method">
                <a-select-option value="干接点">干接点</a-select-option>
                <a-select-option value="模拟量">模拟量</a-select-option>
                <a-select-option value="接口">接口</a-select-option>
                <a-select-option value="无">无</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="压力调节方法" prop="pressure_regulation_method">
              <a-select :disabled="showType=='detail'" v-model.trim="pipelineParams.pressure_regulation_method">
                <a-select-option value="手动">手动</a-select-option>
                <a-select-option value="定时器">定时器</a-select-option>
                <a-select-option value="系统控制">系统控制</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="pipelineParams.function">
                <a-select-option value="阀门">阀门</a-select-option>
                <a-select-option value="水龙头">水龙头</a-select-option>
                <a-select-option value="过滤器">过滤器</a-select-option>
                <a-select-option value="指示器">指示器</a-select-option>
                <a-select-option value="水锤消除器">水锤消除器</a-select-option>
                <a-select-option value="水表">水表</a-select-option>
                <a-select-option value="报警阀">报警阀</a-select-option>
                <a-select-option value="杀菌消毒装置">杀菌消毒装置</a-select-option>
                <a-select-option value="水质处理装置">水质处理装置</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="pipelineParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================容器设施====================== -->
          <div v-if="formData.equipment_param_type=='35'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="容积" prop="volume">
              <a-input :disabled="showType=='detail'" v-model.trim="containerFacilitiesParams.volume" />
            </a-form-model-item>
            <a-form-model-item label="工作压力（Mpa）" prop="working_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="containerFacilitiesParams.working_pressure" />
            </a-form-model-item>
            <a-form-model-item label="材质" prop="material_quality">
              <a-input :disabled="showType=='detail'" v-model.trim="containerFacilitiesParams.material_quality" />
            </a-form-model-item>
            <a-form-model-item label="功能" prop="function">
              <a-select :disabled="showType=='detail'" v-model.trim="containerFacilitiesParams.function">
                <a-select-option value="水箱（池）">水箱（池）</a-select-option>
                <a-select-option value="油箱">油箱</a-select-option>
                <a-select-option value="气压罐">气压罐</a-select-option>
                <a-select-option value="过滤装置">过滤装置</a-select-option>
                <a-select-option value="集/分水器">集/分水器</a-select-option>
                <a-select-option value="储气罐">储气罐</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="containerFacilitiesParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================空调室外机====================== -->
          <div v-if="formData.equipment_param_type=='36'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="室外机容量" prop="volume">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.volume" />
            </a-form-model-item>
            <a-form-model-item label="室内机最大连接台数" prop="max_link_num">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.max_link_num" />
            </a-form-model-item>
            <a-form-model-item label="制冷剂型号" prop="refrigerant_model">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.refrigerant_model" />
            </a-form-model-item>
            <a-form-model-item label="制冷剂注入量" prop="refrigerant_injection_volume">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.refrigerant_injection_volume" />
            </a-form-model-item>
            <a-form-model-item label="工作电压" prop="working_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.working_voltage" />
            </a-form-model-item>
            <a-form-model-item label="高压接管尺寸（mm)" prop="high_pressure_nozzle_size">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.high_pressure_nozzle_size" />
            </a-form-model-item>
            <a-form-model-item label="低压接管尺寸（mm)" prop="low_pressure_nozzle_size">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.low_pressure_nozzle_size" />
            </a-form-model-item>
            <a-form-model-item label="制冷量(kW)" prop="refrigeration_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.refrigeration_capacity" />
            </a-form-model-item>
            <a-form-model-item label="制冷额定功率(kw)" prop="rated_power_refrigeration">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.rated_power_refrigeration" />
            </a-form-model-item>
            <a-form-model-item label="制冷额定电流（A）" prop="rated_cooling_current">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.rated_cooling_current" />
            </a-form-model-item>
            <a-form-model-item label="制热量(kW)" prop="heating_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.heating_capacity" />
            </a-form-model-item>
            <a-form-model-item label="制热额定功率(kw)" prop="heating_rated_power">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.heating_rated_power" />
            </a-form-model-item>
            <a-form-model-item label="制热额定电流（A）" prop="heating_rated_current">
              <a-input :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.heating_rated_current" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="outdoorAirConditioningParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================空调室内机====================== -->
          <div v-if="formData.equipment_param_type=='37'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="风量" prop="air_volume">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.air_volume" />
            </a-form-model-item>
            <a-form-model-item label="噪声" prop="noise">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.noise" />
            </a-form-model-item>
            <a-form-model-item label="安装形式" prop="installation_form">
              <a-select :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.installation_form">
                <a-select-option value="嵌入式">嵌入式</a-select-option>
                <a-select-option value="壁挂式">壁挂式</a-select-option>
                <a-select-option value="落地式">落地式</a-select-option>
                <a-select-option value="隐藏式">隐藏式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="冷媒" prop="refrigerant">
              <a-select :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.refrigerant">
                <a-select-option value="制冷剂">制冷剂</a-select-option>
                <a-select-option value="无">无</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="高压接管尺寸（mm)" prop="high_pressure_nozzle_size">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.high_pressure_nozzle_size" />
            </a-form-model-item>
            <a-form-model-item label="低压接管尺寸（mm)" prop="low_pressure_nozzle_size">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.low_pressure_nozzle_size" />
            </a-form-model-item>
            <a-form-model-item label="冷凝水接管尺寸（mm)" prop="condensate_water_nozzle_size">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.condensate_water_nozzle_size" />
            </a-form-model-item>
            <a-form-model-item label="电压" prop="voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="current">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.current" />
            </a-form-model-item>
            <a-form-model-item label="制冷量(kW)" prop="refrigeration_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.refrigeration_capacity" />
            </a-form-model-item>
            <a-form-model-item label="制热量(kW)" prop="heating_capacity">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.heating_capacity" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">温控器</h3>
            <a-form-model-item label="型号" prop="temperature_controller_model">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.temperature_controller_model" />
            </a-form-model-item>
            <a-form-model-item label="面板形式" prop="temperature_controller_panel_form">
              <a-select :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.temperature_controller_panel_form">
                <a-select-option value="液晶式">液晶式</a-select-option>
                <a-select-option value="机械式">机械式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="控制方式" prop="temperature_controller_method">
              <a-select :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.temperature_controller_method">
                <a-select-option value="本地">本地</a-select-option>
                <a-select-option value="本地遥控">本地遥控</a-select-option>
                <a-select-option value="远程">远程</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">冷媒阀</h3>
            <a-form-model-item label="型号" prop="refrigerant_valve_model">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.refrigerant_valve_model" />
            </a-form-model-item>
            <a-form-model-item label="管径" prop="refrigerant_valve_diameter">
              <a-input :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.refrigerant_valve_diameter" />
            </a-form-model-item>
            <a-form-model-item label="形式" prop="refrigerant_valve_form">
              <a-select :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.refrigerant_valve_form">
                <a-select-option value="电磁阀">电磁阀</a-select-option>
                <a-select-option value="调节阀">调节阀</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="indoorAirConditioningParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================消防卷帘===================== -->
          <div v-if="formData.equipment_param_type=='38'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="耐火等级" prop="fire_resistance_rating">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.fire_resistance_rating" />
            </a-form-model-item>
            <a-form-model-item label="材质" prop="material_quality">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.material_quality" />
            </a-form-model-item>
            <a-form-model-item label="耐火时间（h）" prop="fire_resistance_time">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.fire_resistance_time" />
            </a-form-model-item>
            <a-form-model-item label="尺寸" prop="rolling_shutter_size">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.rolling_shutter_size" />
            </a-form-model-item>
            <a-form-model-item label="阻断位置" prop="blocking_position">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.blocking_position" />
            </a-form-model-item>
            <a-form-model-item label="阻断功能" prop="blocking_function">
              <a-select :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.blocking_function">
                <a-select-option value="疏散通道">疏散通道</a-select-option>
                <a-select-option value="非疏散通道">非疏散通道</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="卷闸形式" prop="roll_gate_form">
              <a-select :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.roll_gate_form">
                <a-select-option value="正常">正常</a-select-option>
                <a-select-option value="异形">异形</a-select-option>
              </a-select>
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">拖动电动机</h3>
            <a-form-model-item label="型号" prop="drive_motor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.drive_motor_model" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="drive_motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.drive_motor_power" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="drive_motor_power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.drive_motor_power_factor" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="drive_motor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="电压（V）" prop="drive_motor_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.drive_motor_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="drive_motor_current">
              <a-input :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.drive_motor_current" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="fireRollingShutterParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- ===================楼宇对讲===================== -->
          <div v-if="formData.equipment_param_type=='39'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="形式" prop="building_intercom_form">
              <a-select :disabled="showType=='detail'" v-model.trim="buildingIntercomParams.building_intercom_form">
                <a-select-option value="可视对讲">可视对讲</a-select-option>
                <a-select-option value="非可视">非可视</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="通讯方式" prop="communication_method">
              <a-select :disabled="showType=='detail'" v-model.trim="buildingIntercomParams.communication_method">
                <a-select-option value="总线式">总线式</a-select-option>
                <a-select-option value="网络式">网络式</a-select-option>
                <a-select-option value="多线式">多线式</a-select-option>
                <a-select-option value="无线式">无线式</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="类型" prop="building_intercom_type">
              <a-select :disabled="showType=='detail'" v-model.trim="buildingIntercomParams.building_intercom_type">
                <a-select-option value="管理中心机">管理中心机</a-select-option>
                <a-select-option value="围墙机">围墙机</a-select-option>
                <a-select-option value="门口机">门口机</a-select-option>
                <a-select-option value="户内分机">户内分机</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="识别支持" prop="identification_support">
              <a-select :disabled="showType=='detail'" v-model.trim="buildingIntercomParams.identification_support">
                <a-select-option value="读卡器">读卡器</a-select-option>
                <a-select-option value="人脸识别">人脸识别</a-select-option>
                <a-select-option value="指纹">指纹</a-select-option>
                <a-select-option value="虹膜">虹膜</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="工作电压" prop="working_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="buildingIntercomParams.working_voltage" />
            </a-form-model-item>
            <a-form-model-item label="屏幕尺寸" prop="screen_size">
              <a-input :disabled="showType=='detail'" v-model.trim="buildingIntercomParams.screen_size" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="buildingIntercomParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================液压停车架===================== -->
          <div v-if="formData.equipment_param_type=='40'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="车位数量" prop="parking_space_num">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.parking_space_num" />
            </a-form-model-item>
            <a-form-model-item label="层数" prop="parking_space_floor">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.parking_space_floor" />
            </a-form-model-item>
            <a-form-model-item label="车位宽度" prop="parking_space_width">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.parking_space_width" />
            </a-form-model-item>
            <a-form-model-item label="车位长度" prop="parking_space_length">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.parking_space_length" />
            </a-form-model-item>
            <a-form-model-item label="车位高度" prop="parking_space_height">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.parking_space_height" />
            </a-form-model-item>
            <a-form-model-item label="驱动方式" prop="driving_method">
              <a-select :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.driving_method">
                <a-select-option value="油压">油压</a-select-option>
                <a-select-option value="其它">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="提升重量" prop="lifting_weight">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.lifting_weight" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">驱动装置</h3>
            <a-form-model-item label="型号" prop="drive_device_model">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.drive_device_model" />
            </a-form-model-item>
            <a-form-model-item label="工作压力" prop="working_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.working_pressure" />
            </a-form-model-item>
            <a-form-model-item label="提升压力" prop="raise_pressure">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.raise_pressure" />
            </a-form-model-item>
            <a-form-model-item label="工作电压" prop="working_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.working_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电功率" prop="electric_power">
              <a-input :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.electric_power" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="HydraulicParkingRackParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>

          <!-- =====================道闸===================== -->
          <div v-if="formData.equipment_param_type=='41'">
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;">主要参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">设施主要参数</h3>
            <a-form-model-item label="功能" prop="barrier_gate_function">
              <a-select :disabled="showType=='detail'" v-model.trim="barrierFateParams.barrier_gate_function">
                <a-select-option value="门禁道闸">门禁道闸</a-select-option>
                <a-select-option value="车场道闸">车场道闸</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="道闸形式" prop="barrier_gate_form">
              <a-select :disabled="showType=='detail'" v-model.trim="barrierFateParams.barrier_gate_form">
                <a-select-option value="摆闸">摆闸</a-select-option>
                <a-select-option value="翼闸">翼闸</a-select-option>
                <a-select-option value="滚闸">滚闸</a-select-option>
                <a-select-option value="旋转闸">旋转闸</a-select-option>
                <a-select-option value="闸杆">闸杆</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="通道宽度" prop="channel_width">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.channel_width" />
            </a-form-model-item>
            <a-form-model-item label="道闸材质" prop="gate_material">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.gate_material" />
            </a-form-model-item>
            <a-form-model-item label="控制接口" prop="control_interface">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.control_interface" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">附属设施、主要零部件及其参数</h3>
            <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">拖动电动机</h3>
            <a-form-model-item label="型号" prop="drive_motor_model">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.drive_motor_model" />
            </a-form-model-item>
            <a-form-model-item label="功率（KW）" prop="drive_motor_power">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.drive_motor_power" />
            </a-form-model-item>
            <a-form-model-item label="功率因数" prop="drive_motor_power_factor">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.drive_motor_power_factor" />
            </a-form-model-item>
            <a-form-model-item label="转速（rpm）" prop="drive_motor_speed">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.drive_motor_speed" />
            </a-form-model-item>
            <a-form-model-item label="电压（V）" prop="drive_motor_voltage">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.drive_motor_voltage" />
            </a-form-model-item>
            <a-form-model-item label="电流（A）" prop="drive_motor_current">
              <a-input :disabled="showType=='detail'" v-model.trim="barrierFateParams.drive_motor_current" />
            </a-form-model-item>
            <h3 style="font-size: large;font-weight: bold; color: #0388fd;;">备注</h3>
            <a-form-model-item label="" prop="remark">
              <textarea :disabled="showType=='detail'" v-model.trim="barrierFateParams.remark" style="width: 305px;margin-left: 150px"/>
            </a-form-model-item>
          </div>



        </div>
        <div v-show="activeKey == '4'">
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">设备标识卡</h3>
          <a-form-model-item label="设备编号" prop="equipment_num">
            <a-input :disabled="true" v-model.trim="formData.equipment_num" :addon-before="equipment_num_before"/>
          </a-form-model-item>
          <a-form-model-item label="设备名称" prop="equipment_name">
            <a-input :disabled="true" v-model.trim="formData.equipment_name" />
          </a-form-model-item>
          <a-form-model-item label="型号规格" prop="model_specification">
            <a-input :disabled="true" v-model.trim="formData.model_specification" />
          </a-form-model-item>
          <a-form-model-item label="用途" prop="purpose">
            <textarea :disabled="true" v-model.trim="formData.purpose" style="width: 305px"/>
          </a-form-model-item>
          <a-form-model-item label="二维码" prop="qrcode">
            <div id="qrCode" ref="qrCodeDiv"></div>
          </a-form-model-item>
          <a-form-model-item label="公司logo" prop="logo">
            <a-upload
                :disabled="true"
                name="fileList"
                :headers="uploadHeaders"
                :data="uploadFacilityEquipment"
                list-type="picture-card"
                action="/upload"
                :file-list="logoPicList"
                @preview="handlePreview(formData.logo_pic)"
                @change="logoPicUpload">
              <a-button v-if="false"><a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
          <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">上级设备</h3>
          <a-form-model v-if="showType!='detail'" :model="queryParam" :label-col="{span:10}" :wrapper-col="{span:14}">
            <a-form-model-item label="设备类别" prop="equipment_category" style="width: 24%">
              <a-select v-model="queryParam.equipment_category1" @change="getEquipmentChildCategory1(queryParam.equipment_category1)">
                <a-select-option v-for="(item, index) in equipmentCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设备子类别" prop="equipment_child_category" style="width: 24%">
              <a-select v-model="queryParam.equipment_child_category1" @change="getEquipDirectoriesList1()">
                <a-select-option v-for="(item, index) in equipmentChildCategoryList1" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设备名称" prop="equipment_name1" style="width: 24%">
              <a-select v-model="queryParam.equipment_name1"
                        show-search
                        option-filter-prop="children"
                        :filter-option="filterOption"
                        @change="onChange1">
                <a-select-option v-for="(item, index) in equipmentNumList1" :key="index" :value="item.dicvalue">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%;margin-top: 5px">
              <a-button @click="resetQueryParam" style="margin-right: 10px">重置</a-button>
            </div>
          </a-form-model>
          <a-divider></a-divider>
          <a-form :form="form">
            <dynamic-form :title="`${PARTONE}`" :arr="arr1" :showType="showType" @update-result="dynamicFormChange1"></dynamic-form>
          </a-form>
          <h3 style="font-weight: bold;color: #fdbe00;margin-left: 30px">下级设备</h3>
          <a-form-model v-if="showType!='detail'" :model="queryParam" :label-col="{span:10}" :wrapper-col="{span:14}">
            <a-form-model-item label="设备类别" prop="equipment_category" style="width: 24%">
              <a-select v-model="queryParam.equipment_category2" @change="getEquipmentChildCategory2(queryParam.equipment_category2)">
                <a-select-option v-for="(item, index) in equipmentCategoryList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设备子类别" prop="equipment_child_category" style="width: 24%">
              <a-select v-model="queryParam.equipment_child_category2" @change="getEquipDirectoriesList2()">
                <a-select-option v-for="(item, index) in equipmentChildCategoryList2" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设备名称" prop="equipment_name2" style="width: 24%">
              <a-select v-model="queryParam.equipment_name2"
                        show-search
                        option-filter-prop="children"
                        :filter-option="filterOption"
                        @change="onChange2"
              >
                <a-select-option v-for="(item, index) in equipmentNumList2" :key="index" :value="item.dicvalue">{{item.dicvalue}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%;margin-top: 5px">
              <a-button @click="resetQueryParam" style="margin-right: 10px">重置</a-button>
            </div>
          </a-form-model>
          <a-divider></a-divider>
          <a-form :form="form">
            <dynamic-form :title="`${PARTTWO}`" :arr="arr2" :showType="showType" @update-result="dynamicFormChange2"></dynamic-form>
          </a-form>
        </div>
        <div v-show="activeKey == '5'">
          <h3 style="font-size: large;font-weight: bold; color: #0388fd;">保养记录</h3>
          <div class="box-container" style="margin-top: 10px;">
            <div class="box-container-inner">
              <div class="simple-query">
                <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
                  <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 24%">
                    <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
                  </a-form-model-item>
                  <a-form-model-item label="保养单号" prop="order_num" style="width: 24%;margin-top: 5px">
                    <a-input v-model="queryParams.order_num"></a-input>
                  </a-form-model-item>
                  <a-form-model-item label="节点状态" prop="process_node" style="width: 24%">
                    <a-select v-model="queryParams.process_node">
                      <a-select-option value="">所有</a-select-option>
                      <a-select-option value="20">待组长派单</a-select-option>
                      <a-select-option value="30">待组员派单</a-select-option>
                      <a-select-option value="40">待组员转单</a-select-option>
                      <a-select-option value="50">待签到</a-select-option>
                      <a-select-option value="60">待维保</a-select-option>
                      <a-select-option value="70">待组长审核</a-select-option>
                      <a-select-option value="71">完成</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <div class="query-btns" style="float: right;width: 24%">
                    <a-button @click="resetQueryParams">重置</a-button>
                    <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--                    <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
                  </div>
                  <div v-show="showAdvanced" class="advanced-query">
                  </div>
                </a-form-model>
              </div>
            </div>
            <div class="box-container-inner">
              <div class="table-header">
                <div class="table-btns">
                  <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
                </div>
              </div>
              <a-table :columns="tableColumns" :data-source="tableData" :customRow="customRow" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
                <span slot="need_finish_time" slot-scope="value">
                  <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
                    <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
                  </a-tooltip>
                </span>
                <span slot="finish_time" slot-scope="value">
                  <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
                    <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
                  </a-tooltip>
                </span>
                <span slot="process_node" slot-scope="value">
                  <a-tag :color="orderStepColor[value]||''">{{orderSteps[value]||''}}</a-tag>
                </span>
                <span slot="equipment_type" slot-scope="value,record">{{value.length==2?value.substring(0,1)==0?deviceCodeList[value.replace('0','')-1].dicvalue:deviceCodeList[value-1].dicvalue:''}}</span>
                <span slot="maintenance_period" slot-scope="value">
                  <span :title="value?maintenancePeriodList[value].dicvalue:''">{{value?maintenancePeriodList[value].dicvalue:''}}</span>
                </span>
                <span slot="plan_status" slot-scope="value">
                  <span :title="value==1?'运行':'停止'">{{value==1?'运行':'停止'}}</span>
                </span>
                <span slot="result_code" slot-scope="value">
                  <span>{{resultCode[value]}}</span>
                </span>
                <span slot="maintenance_schedule_type" slot-scope="value,record">{{equipmentMaintenanceItemList[value]?equipmentMaintenanceItemList[value].dicvalue:''}}</span>
                <span slot="operation" slot-scope="value, record">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                      <a-icon type="menu"/>
                    </a>
                    <a-menu slot="overlay" @click="operationClick">
                      <a-menu-item :key="'detail-'+record.maintenance_order_id">详情</a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </span>
              </a-table>
              <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
                <template slot="buildOptionText" slot-scope="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </div>
          </div>
        </div>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel" width="1080px">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <detail-modal :visible.sync="maintenanceTaskVisible" :show-type="maintenanceTaskShowType" :detailData="maintenanceTaskDetailData" @get-operation-result="getTableData"></detail-modal>
  </div>
</template>
<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import QRCode from 'qrcodejs2';
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {
  addEquipDirectories, exportEquipmentIDCardWord, getEquipDirectoriesList,
  getEquipDirectoriesListByCondition,
  modifyEquipDirectories
} from "A/facilityandequipment";
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {getCache, getItemFromArrayByKey} from "U/index";
import {getSecondaryDeviceList, getThirdDeviceList} from "A/jcgn";
import pagination from "@/mixins/pagination";
import {getMaintenanceOrderListByCondition} from "A/maintenance";
import {orderStepColor, orderStepList, orderSteps, resultCode} from "@/json/orderSteps";
import DetailModal from "V/facilityAndEquipment/maintenanceManagement/maintenance/task/DetailModal";
import DynamicForm from "V/facilityAndEquipment/basicManagement/directories/dynamicform";

const PARTONE = 'partOne'
const PARTTWO = 'partTwo'

export default {
  mixins: [areaselect, deptselect,pagination],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    ActiveKey: {
      default: '1'
    },
  },
  components: {
    DetailModal,
    DynamicForm,
  },
  data() {
    return {
      moment,
      size: 'default',
      modalBodyStyle: {
        height: '800px',
        overflowY: 'auto',
      },
      orderStepList,
      orderSteps,
      orderStepColor,
      resultCode,
      modalVisible: false,
      showAdvanced: false,
      activeKey: '1',
      //设备编号前缀
      equipment_num_before:'',
      //设备流水码
      serial_number:'',
      //安装位置
      address:'',
      equipmentList:{
        superior_name_1:'',
        superior_num_1:'',
        superior_position_1:'',
        superior_name_2:'',
        superior_num_2:'',
        superior_position_2:'',

        subordinate_name_1:'',
        subordinate_num_1:'',
        subordinate_position_1:'',
      },
      formData: {
        //基础信息
        directories_id: '',
        monitorpointnum: '',
        monitorpointname: '',
        equipment_status: '',
        is_history:'0',
        create_time: '',


        //设施设备台账
        equipment_type: '',
        equipment_name: '',
        model_specification: '',
        factory_no: '',
        manufacturer: '',
        origin_area: '',
        produce_time: '',
        overall_dimension: '',
        weight: '',
        original_value: '',
        equipment_num: '',
        draw_num: '',
        equipment_quantity:'',
        equipment_param_type: '',
        equipment_category: '',
        equipment_child_category: '',
        is_fire_equipment: '',
        address: undefined,
        operation_department: '',
        equipment_level: '',
        archives_num: '',
        enable_time: '',
        purpose: '',
        fire_compartment: '',
        remarks: '',
        equipment_pic: '',


        //主要参数
        main_parameter:'',


        //设备标识卡
        //设备二维码
        qrcode: '',
        logo_pic: '',
        //1.上级设备
        superior_position: '',
        superior_name: '',
        superior_num: '',
        //2.下级设备
        subordinate_position: '',
        subordinate_name: '',
        subordinate_num: '',
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目名称'}],
        equipment_type: [{required: true, message: '请选择设备类型'}],
        equipment_num: [
          {required: true, message: '请输入设备编号'},
          {
            validator: (rule, value, callback) => {
              if(/^.{8}[DP]$/.test(value)) {
                callback();
              }else {
                callback('请确保最后一位为D或者P,且长度为9位')
              }
            }, trigger: 'blur'
          }
        ],
        equipment_name: [{required: true, message: '请输入设备名称'}],
        // equipment_param_type: [{required: true, message: '请选择参数类别'}],
        equipment_category: [{required: true, message: '请选择设备类别'}],
        equipment_child_category: [{required: true, message: '请选择设备子类别'}],
        is_fire_equipment: [{required: true, message: '请选择是否为消防设备'}],
        equipment_level: [{required: true, message: '请选择设备级别'}],
        draw_num: [{required: true, message: '请输入图位号'}],
        equipment_quantity: [{required: true, message: '请输入数量'}],
        address: [{required: true, message: '请输入安装位置'}],
        purpose: [{required: true, message: '请输入设备用途'}],
        fire_compartment: [{required: true, message: '请输入防火分区'}],
        equipment_status: [{required: true, message: '请选择设备状态'}],
      },
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        equipment_type:'',
        order_num:'',
        process_node:"71",
        maintenance_unit_id:'',
        fire_compartment:'',
        address:'',
      },
      tableData: [],
      tableLoading: false,
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        // {
        //   title: '保养单号',
        //   dataIndex: 'order_num',
        //   key: 'order_num',
        //   ellipsis: true,
        // },
        // {
        //   title: '安装位置',
        //   dataIndex: 'address',
        //   key: 'address',
        //   ellipsis: true,
        // },
        // {
        //   title: '防火分区',
        //   dataIndex: 'fire_compartment',
        //   key: 'fire_compartment',
        //   ellipsis: true,
        // },
        // {
        //   title: '设备类型',
        //   dataIndex: 'equipment_type',
        //   key: 'equipment_type',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'equipment_type' },
        // },
        // {
        //   title: '设备编号',
        //   dataIndex: 'equipment_num',
        //   key: 'equipment_num',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'equipment_num' },
        // },
        {
          title: '设备名称',
          dataIndex: 'equipment_name',
          key: 'equipment_name',
          ellipsis: true,
        },
        {
          title: '维/自保单位',
          dataIndex: 'maintenance_unit_name',
          key: 'maintenance_unit_name',
          ellipsis: true,
        },
        {
          title: '维/自保组',
          dataIndex: 'maintenance_group_name',
          key: 'maintenance_group_name',
          ellipsis: true,
        },
        {
          title: '保养周期',
          dataIndex: 'maintenance_period',
          key: 'maintenance_period',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenance_period' },
        },
        // {
        //   title: '保养类型',
        //   dataIndex: 'maintenance_schedule_type',
        //   key: 'maintenance_schedule_type',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'maintenance_schedule_type' },
        // },
        {
          title: '保养日期',
          dataIndex: 'finish_time',
          key: 'finish_time',
          ellipsis: true,
          scopedSlots: { customRender: 'finish_time' },
        },
        {
          title: '保养结论',
          dataIndex: 'result_code',
          key: 'result_code',
          ellipsis: true,
          scopedSlots: { customRender: 'result_code' },
        },
        // {
        //   title: '计划完成日期',
        //   dataIndex: 'need_finish_time',
        //   key: 'need_finish_time',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'need_finish_time' },
        // },
        // {
        //   title: '节点状态',
        //   dataIndex: 'process_node',
        //   key: 'process_node',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'process_node' },
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      equipmentNumList:[],
      userdepidCascaderSelected: [],
      maintenanceTaskVisible: false,
      maintenanceTaskShowType: '',
      maintenanceTaskDetailData: {},

      //高压柜主要参数
      highVoltageCabinetParams:{
        //设备主要参数
        voltage_level: '',
        conditions: '',
        internal_structure:'',
        function: '',
        incoming_circuit_num:'',
        incoming_mode: '',
        outgoing_circuits_num: '',
        outgoing_mode: '',

        //附属设施、主要零部件及其参数
        //1.开关
        switch_model: '',
        switch_standard_code: '',
        switch_rated_voltage: '',
        switch_form: '',
        switch_protection_grade: '',
        switch_short_circuit_current: '',
        switch_short_circuit_withstand_current: '',
        switch_rated_current: '',
        switch_power_supply: '',
        //2.电压互感器
        voltage_transformer_model: '',
        voltage_transformer_rated_capacity: '',
        voltage_transformer_ratio: '',
        voltage_transformer_num: '',
        voltage_transformer_rated_voltage: '',
        voltage_transformer_rated_voltage1: '',
        voltage_transformer_accuracy_level: '',
        voltage_transformer_rated_voltage2: '',
        //3.电流互感器
        current_transformer_model: '',
        current_transformer_rated_capacity: '',
        current_transformer_ratio: '',
        current_transformer_num: '',
        current_transformer_voltage: '',
        current_transformer_rated_current1: '',
        current_transformer_accuracy_level: '',
        current_transformer_rated_current2: '',
        //4.零序电流互感器
        zero_current_transformer_model: '',
        zero_current_transformer_rated_capacity: '',
        zero_current_transformer_ratio: '',
        zero_current_transformer_num: '',
        zero_current_transformer_voltage: '',
        zero_current_transformer_rated_current1: '',
        zero_current_transformer_accuracy_level: '',
        zero_current_transformer_rated_current2: '',

        //备注
        remark:'',
      },
      //直流柜主要参数
      DCScreenParams:{
        //设备主要参数
        input_voltage: '',
        output_voltage: '',
        output_current:'',
        function: '',

        //附属设施、主要零部件及其参数
        //1.电瓶
        battery_model: '',
        battery_form: '',
        battery_rated_voltage: '',
        battery_num: '',
        battery_operation_mode: '',
        battery_rated_capacity: '',

        //备注
        remark:'',
      },
      //变压器主要参数
      transformerParams:{
        //设备主要参数
        rated_capacity: '',
        rated_voltage: '',
        rated_current:'',
        connection_group_label: '',
        short_circuit_impedance:'',
        maximum_temperature_rise: '',
        cooling_mode: '',
        conditions: '',

        //附属设施、主要零部件及其参数
        //1.冷却风机
        cooling_fan_model: '',
        cooling_fan_volume: '',
        cooling_fan_speed: '',
        cooling_fan_num: '',
        cooling_fan_power: '',
        cooling_fan_phase_num: '',
        cooling_fan_noise: '',
        cooling_fan_rated_voltage: '',
        cooling_fan_rated_current: '',
        //2.温度控制器
        temperature_controller_model: '',
        temperature_controller_measuring_range: '',
        temperature_controller_sensor_param: '',
        temperature_controller_resolving: '',
        temperature_controller_measure_accuracy: '',
        temperature_controller_control_accuracy: '',

        //备注
        remark:'',
      },
      //低压柜主要参数
      lowVoltageCabinetParams:{
        //设备主要参数
        voltage_level: '',
        conditions: '',
        internal_structure:'',
        function: '',
        incoming_circuit_num:'',
        incoming_mode: '',
        outgoing_circuits_num: '',
        outgoing_mode: '',
        horizontal_generator_current: '',
        vertical_generator_current: '',
        generator_form: '',
        // feeding_units: '',

        // //附属设施、主要零部件及其参数
        affiliatedParams:[
          {
            //开关
            switch_model: '',
            switch_rated_voltage: '',
            switch_rated_current: '',
            switch_form: '',
            switch_release_current: '',
            switch_breaking_capacity: '',
            // switch_power_supply: '',
            //电流互感器
            current_transformer_model: '',
            current_transformer_rated_capacity: '',
            current_transformer_ratio: '',
            current_transformer_num: '',
            current_transformer_voltage: '',
            current_transformer_rated_current1: '',
            current_transformer_accuracy_level: '',
            current_transformer_rated_current2: '',
          },
        ],

        //备注
        remark:'',
      },
      //电容补偿柜主要参数
      compensationCabinetParams:{
        //设备主要参数
        voltage_level: '',
        conditions: '',
        internal_structure:'',
        power_factor: '',
        incoming_mode: '',
        outgoing_mode: '',
        cooling_fan:'',
        residual_voltage: '',

        //附属设施、主要零部件及其参数
        //1.开关
        switch_model: '',
        switch_rated_voltage: '',
        operation_mode: '',
        switch_rated_current: '',
        switch_form: '',
        switch_melt_rated_current: '',
        //2.电流互感器
        current_transformer_model: '',
        current_transformer_rated_capacity: '',
        current_transformer_ratio: '',
        current_transformer_num: '',
        current_transformer_voltage: '',
        current_transformer_rated_current1: '',
        current_transformer_accuracy_level: '',
        current_transformer_rated_current2: '',
        //3.接触器
        contactor_model: '',
        contactor_rated_voltage: '',
        suction_coil_voltage: '',
        contactor_num: '',
        contactor_rated_current: '',
        auxiliary_contact_num: '',
        //4.热继电器
        thermal_relay_model: '',
        thermal_relay_rated_voltage : '',
        current_regulation_range: '',
        thermal_relay_num: '',
        thermal_relay_rated_current: '',
        reset_mode: '',
        //5.电容器
        capacitor_model: '',
        capacitor_rated_voltage: '',
        capacitor_rated_capacity: '',
        capacitor_num: '',
        capacitor_rated_current: '',
        connection_mode: '',
        //5.自动补偿仪
        automatic_compensator_model: '',
        control_circuits_num: '',
        temperature_control: '',

        //备注
        remark:'',
      },
      //柴油发电机主要参数
      dieselGeneratorParams:{
        //设备主要参数
        alternator_mode: '',
        alternator_manufacturer: '',
        normal_power:'',
        spare_power: '',
        diesel_engine_model:'',
        diesel_engine_manufacturer: '',
        cylinder_num: '',
        power_factor: '',
        speed: '',
        battery_voltage: '',
        battery_number: '',
        cooling_mode: '',

        //附属设施、主要零部件及其参数
        //1.启动电瓶
        battery_model: '',
        battery_form: '',
        battery_rated_voltage: '',
        battery_num: '',
        operation_mode: '',
        battery_rated_capacity: '',
        //2.燃油系统
        tank_capacity: '',
        oil_model: '',
        fuel_filler_position: '',
        //3.排烟系统
        handle_mode: '',
        smoke_exhaust_pipe: '',
        smoke_outlet_position: '',
        //4.进风系统
        air_inlet_fan_model: '',
        air_inlet_fan_num: '',
        air_inlet_position: '',
        //5.排风系统
        exhaust_fan_model: '',
        exhaust_fan_num: '',
        air_outlet_position: '',
        //6.水冷系统
        cooling_tower_model: '',
        cooling_tower_num: '',
        cooling_tower_position: '',

        //备注
        remark:'',
      },
      //计算机主要参数
      computerParams:{
        //设备主要参数
        CPU_mode: '',
        hard_disk: '',
        memory:'',
        CD_drive: '',
        monitor:'',
        monitor_interface: '',
        network_card: '',
        network_card_form: '',
        operating_system: '',
        main_function_software: '',
        browser: '',
        anti_virus_software: '',

        //备注
        remark:'',
      },
      //消防主机主要参数
      fireEngineParams:{
        //设备主要参数
        maximum_load_num: '',
        with_loop_num: '',
        loop_load_num:'',
        communication_interface: '',
        install_load_num:'',
        install_loop_num: '',
        alarm_output: '',
        programmable_output: '',

        //附属设施、主要零部件及其参数
        //1.备用电瓶
        spare_battery_model: '',
        spare_battery_form: '',
        spare_battery_rated_voltage: '',
        spare_battery_num: '',
        operation_mode: '',
        spare_battery_capacity: '',
        //2.总线盘
        board_install_point: '',
        board_used_point: '',
        //3.多线盘
        multi_wire_install_point: '',
        multi_wire_used_point: '',
        //4.接入负载数
        smoke_sensation: '',
        manual_alarm: '',
        water_flow_switch: '',
        warm_feeling: '',
        fire_hydrant_button: '',
        valve_status: '',
        fire_indicator: '',
        manual_auto_state: '',
        unfavourable_water_pressure: '',
        fan_state: '',
        water_pump_state: '',
        fire_shutter_state: '',
        fan_control: '',
        water_pump_control: '',
        fire_shutter_control: '',
        elevator_forced_landing_state: '',
        non_elimination_power_state: '',
        fire_broadcasting: '',
        elevator_forced_landing_control: '',
        non_elimination_power_control: '',
        audible_visual_alarm: '',
        other: '',

        //备注
        remark:'',
      },
      //消防电话主机主要参数
      freTelephoneHostParams:{
        //设备主要参数
        installable_extension_num: '',
        installable_jack_num: '',
        work_voltage:'',
        communication_interface: '',

        //附属设施、主要零部件及其参数
        wall_hanging_phone_num: '',
        jack_num: '',
        jack_phone_num: '',

        //备注
        remark:'',
      },
      //广播功放主要参数
      radioAmplifierParams:{
        //设备主要参数
        function: '',
        audio_input: '',
        mic_input:'',
        network_interface: '',
        audio_output:'',
        constant_pressure_output: '',
        fixed_resistance_output: '',
        output_power: '',

        //附属设施、主要零部件及其参数
        //1.音箱
        loudspeaker_num: '',
        loudspeaker_input: '',
        total_power: '',

        //备注
        remark:'',
      },
      //视频矩阵主要参数
      videoMatrixParams:{
        //设备主要参数
        input_sign: '',
        output_sign: '',
        video_input:'',
        communication_input: '',
        input_port:'',
        output_port: '',
        video_output: '',
        communication_output: '',

        //附属设施、主要零部件及其参数
        //1.附属操作键盘
        keyboard_num: '',
        communication_port: '',
        
        //备注
        remark:'',
      },
      //录像机主要参数
      videoRecorderParams:{
        //设备主要参数
        video_input: '',
        video_output: '',
        video_compressed_format:'',
        audio_input: '',
        audio_output:'',
        audio_compressed_format: '',
        alarm_input: '',
        alarm_output: '',
        alarm_compressed_format: '',
        hard_disk_input: '',
        hard_disk_output: '',
        hard_disk_compressed_format: '',
        network_interface: '',
        USB_interface: '',
        communication_interface: '',
        videotape_snapshot: '',
        backups_mode: '',
        playback_mode: '',
        sync_playback: '',

        //备注
        remark:'',
      },
      //模块箱主要参数
      moduleBoxParams:{
        //主控模块
        main_control_brand1: '',
        main_control_model1: '',
        main_control_num1:'',
        main_control_point1: '',
        main_control_used_point1:'',
        main_control_brand2: '',
        main_control_model2: '',
        main_control_num2:'',
        main_control_point2: '',
        main_control_used_point2:'',

        //扩展模块
        extend_brand1: '',
        extend_model1: '',
        extend_num1: '',
        extend_point1: '',
        extend_used_point1: '',
        extend_brand2: '',
        extend_model2: '',
        extend_num2: '',
        extend_point2: '',
        extend_used_point2: '',
        extend_brand3: '',
        extend_model3: '',
        extend_num3: '',
        extend_point3: '',
        extend_used_point3: '',
        extend_brand4: '',
        extend_model4: '',
        extend_num4: '',
        extend_point4: '',
        extend_used_point4: '',

        //备注
        remark:'',
      },
      //分体水泵主要参数
      splitWaterPumpParams:{
        //设备主要参数
        pump_model: '',
        flow: '',
        lift:'',
        pump_serial_num: '',
        withstand_voltage:'',
        necessary_cavitation: '',

        //附属设施、主要零部件及其参数
        //1.拖动电动机
        drive_motor_model: '',
        drive_motor_power: '',
        drive_motor_power_factor: '',
        drive_motor_speed: '',
        drive_motor_voltage: '',
        drive_motor_current: '',

        //备注
        remark:'',
      },
      //一体水泵主要参数
      integratedWaterPumpParams:{
        //设备主要参数
        flow: '',
        lift: '',
        necessary_cavitation:'',
        power: '',
        voltage:'',
        frequency: '',
        power_factor: '',
        current: '',
        speed: '',

        //备注
        remark:'',
      },
      //冷水机主要参数
      coolingWaterMachineParams:{
        //设备主要参数
        refrigeration_capacity: '',
        input_power: '',
        input_voltage:'',
        rated_current: '',
        refrigerant:'',
        compressor_form: '',
        start_form: '',
        condenser_form: '',
        condenser_model: '',
        condenser_water_side_pressure: '',
        evaporator_form: '',
        evaporator_model: '',
        evaporator_water_side_pressure: '',

        //附属设施、主要零部件及其参数
        //1.启动柜
        starting_cabinet_model: '',
        starting_cabinet_voltage: '',
        starting_cabinet_tolerance_voltage: '',
        starting_cabinet_frequency: '',
        starting_cabinet_rated_current: '',

        //备注
        remark:'',
      },
      //冷却塔主要参数
      coolingTowerParams:{
        //设备主要参数
        inlet_water_temperature: '',
        outlet_water_temperature: '',
        dry_bulb_temperature:'',
        wet_bulb_temperature: '',
        cooling_water_volume:'',
        air_volume: '',
        evaporation_loss: '',
        tower_head: '',

        //附属设施、主要零部件及其参数
        //1.拖动电动机
        drive_motor_model: '',
        drive_motor_power: '',
        drive_motor_voltage: '',
        drive_motor_power_factor: '',
        drive_motor_speed: '',
        drive_motor_current: '',
        //2.风机
        blade_diameter: '',
        blade_num: '',
        blade_speed: '',

        //备注
        remark:'',
      },
      //热水锅炉主要参数
      hotWaterBoilerParams:{
        //设备主要参数
        rated_heat_supply: '',
        rated_outlet_water_temperature: '',
        rated_return_water_temperature:'',
        fuel: '',
        boiler_form:'',
        thermal_efficiency: '',
        load_regulation_range: '',
        exhaust_gas_temperature: '',
        monitor_form: '',
        single_set_noise: '',
        boiler_water_capacity: '',
        heat_transfer_area: '',

        //附属设施、主要零部件及其参数
        //1.燃烧机
        combustion_engine_model: '',
        power_range: '',
        motor_power: '',
        combustion_engine_origin_place: '',
        rated_pressure: '',
        used_power: '',
        adjust_form: '',
        //2.锅炉控制器
        boiler_controller_model: '',
        suitable_furnace_type: '',
        boiler_controller_origin_place: '',

        //备注
        remark:'',
      },
      //板式热交换器主要参数
      plateHeatExchangerParams:{
        //设备主要参数
        heat_medium_temperature: '',
        heat_medium_flow: '',
        heat_medium_pressure_drop:'',
        cool_medium_temperature: '',
        cool_medium_flow:'',
        cool_medium_pressure_drop: '',
        heat_exchange_capacity: '',
        heat_exchange_area: '',
        plate_total: '',

        //备注
        remark:'',
      },
      //空气处理机主要参数
      airHandlingUnitParams:{
        //设备主要参数
        fan_model: '',
        paragraph_num: '',
        install_form:'',
        air_valve_num: '',
        air_capacity:'',
        refrigeration_capacity: '',
        heating_capacity: '',
        humidification_capacity: '',
        external_static_pressure: '',
        cooling_coil: '',
        heating_device: '',
        humidification_device: '',
        fan_speed: '',
        fan_pulley: '',
        motor_pulley: '',
        belt: '',

        //附属设施、主要零部件及其参数
        //1.拖动电动机
        drive_motor_model: '',
        drive_motor_power: '',
        drive_motor_power_factor: '',
        drive_motor_speed: '',
        drive_motor_voltage: '',
        drive_motor_current: '',

        //备注
        remark:'',
      },
      //风机主要参数
      fanParams:{
        //设备主要参数
        fan_model: '',
        air_capacity: '',
        static_pressure:'',
        install_form: '',
        fan_speed:'',
        fan_pulley: '',
        motor_pulley: '',
        belt: '',

        //附属设施、主要零部件及其参数
        //1.拖动电动机
        drive_motor_model: '',
        drive_motor_power: '',
        drive_motor_power_factor: '',
        drive_motor_speed: '',
        drive_motor_voltage: '',
        drive_motor_current: '',
        //2.驱动主机
        fire_damper_model: '',
        fire_damper_fusing_temperature: '',

        //备注
        remark:'',
      },
      //曳引梯主要参数
      tractionLadderParams:{
        //设备主要参数
        lift_car: '',
        passenger_num: '',
        dead_weight:'',
        lift_speed: '',
        stations_num:'',
        door_num: '',
        docking_floor: '',
        lifting_height: '',
        control_type: '',
        drive_system: '',

        //附属设施、主要零部件及其参数
        //1.曳引机
        traction_machine_model: '',
        traction_machine_voltage: '',
        traction_machine_current: '',
        traction_machine_num: '',
        traction_machine_power: '',
        traction_machine_transmission_ratio: '',
        //2.限速器
        speed_governor_model: '',
        speed_governor_speed: '',
        machine_action_speed: '',
        speed_governor_num: '',
        speed_governor_tension: '',
        electrical_action_speed: '',
        //3.安全钳
        safety_gear_model: '',
        safety_gear_num: '',
        safety_gear_form: '',
        //4.缓冲器
        buffer_model: '',
        buffer_num: '',
        buffer_form: '',
        //5.变频器
        frequency_converter_model: '',
        frequency_converter_brand: '',
        //6.门锁装置
        door_lock_device_model:'',
        //7.轿厢空调
        car_air_conditioner_model:'',
        car_air_conditioner_refrigeration_capacity:'',
        car_air_conditioner_power:'',
        car_air_conditioner_brand:'',
        car_air_conditioner_refrigerating_fluid:'',
        car_air_conditioner_current:'',
        //8.井道安全门
        shaft_safety_door_floor:'',
        shaft_safety_door_num:'',
        shaft_safety_door_size:'',

        //备注
        remark:'',
      },
      //液压梯主要参数
      hydraulicLadderParams:{
        //设备主要参数
        lift_car: '',
        passenger_num: '',
        dead_weight:'',
        lift_speed: '',
        stations_num:'',
        door_num: '',
        docking_floor: '',
        lifting_height: '',
        control_type: '',
        drive_system: '',

        //附属设施、主要零部件及其参数
        //1.液压柱
        hydraulic_column_model: '',
        hydraulic_column_work_pressure: '',
        hydraulic_column_lift_pressure: '',
        hydraulic_column_num: '',
        hydraulic_column_test_pressure: '',
        //2.限速器
        speed_governor_model: '',
        speed_governor_speed: '',
        machine_action_speed: '',
        speed_governor_num: '',
        speed_governor_tension: '',
        electrical_action_speed: '',
        //3.门锁装置
        door_lock_device_model:'',
        //4.轿厢空调
        car_air_conditioner_model:'',
        car_air_conditioner_refrigeration_capacity:'',
        car_air_conditioner_power:'',
        car_air_conditioner_brand:'',
        car_air_conditioner_refrigerating_fluid:'',
        car_air_conditioner_current:'',

        //备注
        remark:'',
      },
      //扶梯主要参数
      escalatorParams:{
        //设备主要参数
        lifting_height: '',
        tilt_angle: '',
        step_width:'',
        traction_chain_pitch: '',
        transportation_capacity:'',
        speed: '',
        control_type: '',

        //附属设施、主要零部件及其参数
        //1.拖动电动机
        drive_motor_model: '',
        drive_motor_num: '',
        drive_motor_voltage: '',
        drive_motor_electricity: '',
        drive_motor_speed: '',
        drive_motor_insulation: '',
        //2.驱动主机
        drive_host_model: '',
        drive_host_num: '',
        drive_host_voltage: '',
        drive_host_electricity: '',
        drive_host_transmission_mode: '',
        drive_host_transmission_ratio: '',
        //3.制动器
        brake_model: '',
        brake_voltage: '',
        //4.控制屏
        control_panel_model: '',
        control_panel_num: '',
        //5.变频器
        transducer_model: '',
        transducer_brand: '',

        //备注
        remark:'',
      },
      //擦窗机主要参数
      windowCleanerParams:{
        //设备主要参数
        window_cleaner_form: '',
        window_cleaner_rated_load: '',
        window_cleaner_luffing_distance:'',
        window_cleaner_telescoping_height: '',
        window_cleaner_lifting_speed:'',
        window_cleaner_walk_speed: '',
        window_cleaner_luffing_speed: '',
        window_cleaner_swing_speed: '',
        window_cleaner_limited_load_num: '',
        window_cleaner_basket_size: '',
        window_cleaner_motor_power: '',

        //附属设施、主要零部件及其参数
        //1.钢丝绳
        steel_wire_rope_model:'',
        steel_wire_rope_specifications:'',
        steel_wire_rope_length:'',
        //2.安全绳
        safety_rope_model:'',
        safety_rope_specifications:'',
        safety_rope_length:'',
        //3.随行电缆
        traveling_cable_model:'',
        traveling_cable_specifications:'',
        traveling_cable_length:'',

        //备注
        remark:'',
      },
      //烟感主要参数
      smokeSensationParams:{
        //设备主要参数
        smoke_sensation_type: '',
        smoke_sensation_power_type: '',
        smoke_sensation_code_type:'',

        //备注
        remark:'',
      },
      //温感主要参数
      warmFeelingParams:{
        //设备主要参数
        warm_feeling_type: '',
        warm_feeling_power_type: '',
        warm_feeling_code_type:'',
        warm_feeling_product_category:'',

        //备注
        remark:'',
      },
      //破玻按钮主要参数
      breakingGlassParams:{
        //设备主要参数
        breaking_glass_type: '',
        breaking_glass_power_type: '',
        breaking_glass_code_type:'',
        breaking_glass_reset_type:'',

        //备注
        remark:'',
      },
      //消防模块主要参数
      fireModuleParams:{
        //设备主要参数
        fire_module_type: '',
        fire_module_power_type: '',
        fire_module_code_type:'',
        fire_module_point_num:'',

        //备注
        remark:'',
      },
      //消防分机主要参数
      fireExtensionParams:{
        //设备主要参数
        fire_extension_type: '',
        fire_extension_power_type: '',
        fire_extension_code_type:'',
        fire_extension_system_type:'',

        //备注
        remark:'',
      },
      //摄像机主要参数
      videoCameraParams:{
        //设备主要参数
        video_camera_type: '',
        video_camera_power_type: '',
        video_camera_output_interface:'',
        video_camera_photosensitive_size:'',
        video_camera_focal_length:'',
        video_camera_resolving_power:'',
        video_camera_fill_in_light:'',
        video_camera_address:'',

        //备注
        remark:'',
      },
      //红外探头主要参数
      infraredProbeParams:{
        //设备主要参数
        infrared_probe_type: '',
        infrared_probe_working_voltage: '',
        infrared_probe_form:'',
        infrared_probe_distance:'',

        //备注
        remark:'',
      },
      //配电（控制）箱（柜）
      powerDistributionParams:{
        //设备主要参数
        conditions: '',
        function: '',
        incoming_circuit_num:'',
        outgoing_circuit_num: '',

        //附属设施、主要零部件及其参数
        //1.主开关1
        switch1_model: '',
        switch1_rated_voltage: '',
        switch1_rated_current: '',
        switch1_form: '',
        //2.主开关2
        switch2_model: '',
        switch2_rated_voltage: '',
        switch2_rated_current: '',
        switch2_form: '',
        //3.控制器
        controller_model: '',
        controller_type: '',
        controller_effect: '',
        //4.变频器
        converter_brand: '',
        converter_model: '',
        converter_effect: '',

        affiliatedParams:[
          {
            //5.开关/接触器
            contactor_model: '',
            contactor_rated_voltage: '',
            contactor_rated_current: '',
            contactor_form: '',
            suction_coil_voltage: '',
            auxiliary_contact_num: '',
            //6.热继电器
            thermal_relay_model: '',
            thermal_relay_rated_voltage : '',
            current_regulation_range: '',
            thermal_relay_num: '',
            thermal_relay_rated_current: '',
            reset_mode: '',
            //7.电流互感器
            current_transformer_model: '',
            current_transformer_rated_capacity: '',
            current_transformer_ratio: '',
            current_transformer_num: '',
            current_transformer_voltage: '',
            current_transformer_rated_current1: '',
            current_transformer_accuracy_level: '',
            current_transformer_rated_current2: '',
          },
        ],

        //备注
        remark:'',
      },
      //灯具
      luminairesParams:{
        rated_voltage:'',
        rated_current:'',
        conditioner_power:'',
        color_temperature:'',
        install_mode:'',
        controller_mode:'',
        function:'',

        //备注
        remark:'',
      },
      //管道设施
      pipelineParams:{
        diameter:'',
        working_pressure:'',
        connection_method:'',
        driving_method:'',
        feedback_method:'',
        pressure_regulation_method:'',
        function:'',

        //备注
        remark:'',
      },
      //容器设施
      containerFacilitiesParams:{
        volume:'',
        working_pressure:'',
        material_quality:'',
        function:'',

        //备注
        remark:'',
      },
      //空调室外机
      outdoorAirConditioningParams:{
        volume:'',
        max_link_num:'',
        refrigerant_model:'',
        refrigerant_injection_volume:'',
        working_voltage:'',
        high_pressure_nozzle_size:'',
        low_pressure_nozzle_size:'',
        rated_power_refrigeration:'',
        rated_cooling_current:'',
        heating_capacity:'',
        heating_rated_power:'',
        heating_rated_current:'',

        //备注
        remark:'',
      },
      //空调室内机
      indoorAirConditioningParams:{
        air_volume:'',
        noise:'',
        installation_form:'',
        refrigerant:'',
        voltage:'',
        current:'',
        high_pressure_nozzle_size:'',
        low_pressure_nozzle_size:'',
        condensate_water_nozzle_size:'',
        refrigeration_capacity:'',
        heating_capacity:'',
        temperature_controller_model:'',
        temperature_controller_panel_form:'',
        temperature_controller_method:'',
        refrigerant_valve_model:'',
        refrigerant_valve_diameter:'',
        refrigerant_valve_form:'',

        //备注
        remark:'',
      },
      //消防卷帘
      fireRollingShutterParams:{
        //设备主要参数
        fire_resistance_rating: '',
        material_quality: '',
        fire_resistance_time:'',
        rolling_shutter_size: '',
        blocking_position:'',
        blocking_function:'',
        roll_gate_form: '',

        //附属设施、主要零部件及其参数
        //1.拖动电动机
        drive_motor_model: '',
        drive_motor_power: '',
        drive_motor_power_factor: '',
        drive_motor_speed: '',
        drive_motor_voltage: '',
        drive_motor_current: '',

        //备注
        remark:'',
      },
      //楼宇对讲
      buildingIntercomParams:{
        //设备主要参数
        building_intercom_form: '',
        communication_method: '',
        building_intercom_type:'',
        identification_support:'',
        working_voltage:'',
        screen_size:'',

        //备注
        remark:'',
      },
      //液压停车架
      HydraulicParkingRackParams:{
        //设备主要参数
        parking_space_num: '',
        parking_space_floor: '',
        parking_space_width:'',
        parking_space_length:'',
        parking_space_height:'',
        driving_method:'',
        lifting_weight:'',

        //附属设施、主要零部件及其参数
        //1.驱动装置
        drive_device_model:'',
        working_pressure:'',
        raise_pressure:'',
        working_voltage:'',
        electric_power:'',

        //备注
        remark:'',
      },
      //道闸
      barrierFateParams:{
        //设备主要参数
        barrier_gate_function: '',
        barrier_gate_form: '',
        channel_width:'',
        gate_material: '',
        control_interface:'',

        //附属设施、主要零部件及其参数
        //1.拖动电动机
        drive_motor_model: '',
        drive_motor_power: '',
        drive_motor_power_factor: '',
        drive_motor_speed: '',
        drive_motor_voltage: '',
        drive_motor_current: '',

        //备注
        remark:'',
      },

      produceTime:null,
      enableTime:null,
      deviceCodeList:[],
      equipmentStatusList: [],
      equipmentParamsList: [],
      equipmentCategoryList:[],
      equipmentChildCategoryList:[],
      maintenancePeriodList:[],
      equipmentMaintenanceItemList:[],
      baseurl:'https://fda.u-lake.com/',
      equipmentPicList: [],
      logoPicList: [],
      previewImage: '',
      previewVisible:false,
      monitorpointList:[],


      queryParam:{
        equipment_name1:'',
        equipment_name2:'',
        equipment_category1:'',
        equipment_child_category1:'',
        equipment_category2:'',
        equipment_child_category2:'',
      },
      equipmentNumList1:[],
      equipmentChildCategoryList1:[],
      equipmentNumList2:[],
      equipmentChildCategoryList2:[],
      form: this.$form.createForm(this),
      //上下级设备内容
      PARTONE,
      arr1: [ // 模拟从接口获取到的数据（如编辑场景）
        {
          name: "",
          num: "",
          address: "",
        }],
      index1:0,
      //下级设备内容
      PARTTWO,
      arr2: [ // 模拟从接口获取到的数据（如编辑场景）
        {
          name: "",
          num: "",
          address: "",
        }],
      index2:0,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadFacilityEquipment']),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.activeKey=this.ActiveKey
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('update:visible', false);
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    produceTime(val) {
      if(val) {
        this.formData.produce_time = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('produce_time')
      }else {
        this.formData.produce_time = '';
      }
    },
    enableTime(val){
      if(val) {
        this.formData.enable_time = moment(val).format('YYYYMMDD');
        this.$refs.modalForm.clearValidate('enable_time')
      }else {
        this.formData.enable_time = '';
      }
    },
    'formData.equipment_type':function (){
      this.getEquipmentCategory(this.formData.equipment_type)
    },
  },
  created() {
    this.modalVisible = this.visible;
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.deviceCodeList = dictionary.deviceCodeList
      this.equipmentParamsList = dictionary.equipmentParamsList
      this.equipmentCategoryList = dictionary.equipmentCategoryList
      this.equipmentChildCategoryList = dictionary.equipmentChildCategoryList
      this.equipmentStatusList = dictionary.equipmentStatusList
      this.maintenancePeriodList = dictionary.maintenancePeriodList
      this.equipmentMaintenanceItemList = dictionary.equipmentMaintenanceItemList
    }
    this.getEquipDirectoriesList()
    this.initDeptOptionsAll();
  },
  methods: {
    onChange(option){
      this.formData.address=option.replace(/\s*/g,"")
    },
    createEquipmentNum(){
      this.equipment_num_before=
          (this.formData.equipment_type.substr(0,1)==0?"1"+this.formData.equipment_type.substr(1,1):this.formData.equipment_type)
          +(this.formData.is_fire_equipment=="是"?"1":"0")
          +this.formData.equipment_category.substr(-2)
          +this.formData.equipment_child_category.substr(-2)
          // +(this.serial_number.toString().length==1?("0"+this.serial_number):this.serial_number)
    },
    getEquipmentCountByChildCategory(){
      getEquipDirectoriesListByCondition({equipment_child_category:this.formData.equipment_child_category}).then(res => {
        if(res && res.returncode == '0') {
          this.serial_number=res.count+1
          this.serial_number=this.serial_number.toString().length==1?("0"+this.serial_number):this.serial_number
          this.formData.equipment_num=this.serial_number
          this.createEquipmentNum()
        }
      })
    },
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    getEquipDirectoriesList(){
      getEquipDirectoriesList({
        equipment_type:this.queryParam.equipment_type,
        equipment_child_category:this.queryParam.equipment_child_category,
        is_history:'0'
      }).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentNumList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
      this.produceTime=null
      this.enableTime=null
      this.equipmentPicList=[]
      this.logoPicList=[]
      this.tableData=[]
      this.equipment_num_before=""
      this.index1=0
      this.arr1=[{
        name: "",
        num: "",
        address: "",
      }]
      this.index2=0
      this.arr2=[{
        name: "",
        num: "",
        address: "",
      }]
      this.resetQueryParam()
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
    },
    initDetail() {
      console.log(this.detailData)
      if (this.detailData && (this.showType == 'edit' || this.showType == 'detail' || this.showType === 'paste')) {
        this.$nextTick(() => {
          let params = {
            monitorpointnum: this.detailData.monitorpointnum,
            equipment_num: this.detailData.equipment_num
          }
          getEquipDirectoriesListByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.formData = res.item[0];
              this.equipment_num_before=res.item[0].equipment_num.toString().substring(0,7)
              this.formData.equipment_num=res.item[0].equipment_num.toString().substring(7)
              let superior_names = this.formData.superior_name.split(";")
              let superior_nums = this.formData.superior_num.split(";")
              let superior_positions = this.formData.superior_position.split(";")
              let subordinate_names = this.formData.subordinate_name.split(";")
              let subordinate_nums = this.formData.subordinate_num.split(";")
              let subordinate_positions = this.formData.subordinate_position.split(";")
              for(let i=0;i<superior_names.length-1;i++){
                if(i===0){
                  this.arr1[0].name=superior_names[0]
                  this.arr1[0].num=superior_nums[0]
                  this.arr1[0].address=superior_positions[0]
                }else {
                  this.arr1.push({
                    name: superior_names[i],
                    num: superior_nums[i],
                    address: superior_positions[i]
                  })
                }
              }
              for(let j=0;j<subordinate_names.length-1;j++){
                if(j===0){
                  this.arr2[0].name=subordinate_names[0]
                  this.arr2[0].num=subordinate_nums[0]
                  this.arr2[0].address=subordinate_positions[0]
                }else {
                  this.arr2.push({
                    name: subordinate_names[j],
                    num: subordinate_nums[j],
                    address: subordinate_positions[j]
                  })
                }
              }
              if (this.formData.produce_time) {
                this.produceTime = moment(this.formData.produce_time, 'YYYYMMDD')
              }
              if (this.formData.enable_time) {
                this.enableTime = moment(this.formData.enable_time, 'YYYYMMDD')
              }
              if (this.formData.equipment_pic) {
                this.previewImage=this.baseurl+this.formData.equipment_pic
                let nameIndex = this.formData.equipment_pic.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = this.formData.equipment_pic.lastIndexOf('\\');
                }
                let fileName = this.formData.equipment_pic.substr(nameIndex + 1);
                this.equipmentPicList = [{
                  uid: 'equipment_picid',
                  name: fileName,
                  status: 'done',
                  url: this.formData.equipment_pic,
                }];
              }
              if (this.formData.logo_pic) {
                let nameIndex = this.formData.logo_pic.lastIndexOf('/');
                if (nameIndex < 0) {
                  nameIndex = this.formData.logo_pic.lastIndexOf('\\');
                }
                let fileName = this.formData.logo_pic.substr(nameIndex + 1);
                this.logoPicList = [{
                  uid: 'logo_picid',
                  name: fileName,
                  status: 'done',
                  url: this.formData.logo_pic,
                }];
              }
              // 特别数据回显
              switch (this.formData.equipment_param_type) {
                case "0":
                  this.highVoltageCabinetParams=JSON.parse(this.formData.main_parameter)
                  break
                case "1":
                  this.DCScreenParams=JSON.parse(this.formData.main_parameter)
                  break
                case "2":
                  this.transformerParams=JSON.parse(this.formData.main_parameter)
                  break
                case "3":
                  this.lowVoltageCabinetParams=JSON.parse(this.formData.main_parameter)
                  break
                case "4":
                  this.compensationCabinetParams=JSON.parse(this.formData.main_parameter)
                  break
                case "5":
                  this.dieselGeneratorParams=JSON.parse(this.formData.main_parameter)
                  break
                case "6":
                  this.computerParams=JSON.parse(this.formData.main_parameter)
                  break
                case "7":
                  this.fireEngineParams=JSON.parse(this.formData.main_parameter)
                  break
                case "8":
                  this.freTelephoneHostParams=JSON.parse(this.formData.main_parameter)
                  break
                case "9":
                  this.radioAmplifierParams=JSON.parse(this.formData.main_parameter)
                  break
                case "10":
                  this.videoMatrixParams=JSON.parse(this.formData.main_parameter)
                  break
                case "11":
                  this.videoRecorderParams=JSON.parse(this.formData.main_parameter)
                  break
                case "12":
                  this.moduleBoxParams=JSON.parse(this.formData.main_parameter)
                  break
                case "13":
                  this.splitWaterPumpParams=JSON.parse(this.formData.main_parameter)
                  break
                case "14":
                  this.integratedWaterPumpParams=JSON.parse(this.formData.main_parameter)
                  break
                case "15":
                  this.coolingWaterMachineParams=JSON.parse(this.formData.main_parameter)
                  break
                case "16":
                  this.coolingTowerParams=JSON.parse(this.formData.main_parameter)
                  break
                case "17":
                  this.hotWaterBoilerParams=JSON.parse(this.formData.main_parameter)
                  break
                case "18":
                  this.plateHeatExchangerParams=JSON.parse(this.formData.main_parameter)
                  break
                case "19":
                  this.airHandlingUnitParams=JSON.parse(this.formData.main_parameter)
                  break
                case "20":
                  this.fanParams=JSON.parse(this.formData.main_parameter)
                  break
                case "21":
                  this.tractionLadderParams=JSON.parse(this.formData.main_parameter)
                  break
                case "22":
                  this.hydraulicLadderParams=JSON.parse(this.formData.main_parameter)
                  break
                case "23":
                  this.escalatorParams=JSON.parse(this.formData.main_parameter)
                  break
                case "24":
                  this.windowCleanerParams=JSON.parse(this.formData.main_parameter)
                  break
                case "25":
                  this.smokeSensationParams=JSON.parse(this.formData.main_parameter)
                  break
                case "26":
                  this.warmFeelingParams=JSON.parse(this.formData.main_parameter)
                  break
                case "27":
                  this.breakingGlassParams=JSON.parse(this.formData.main_parameter)
                  break
                case "28":
                  this.fireModuleParams=JSON.parse(this.formData.main_parameter)
                  break
                case "29":
                  this.fireExtensionParams=JSON.parse(this.formData.main_parameter)
                  break
                case "30":
                  this.videoCameraParams=JSON.parse(this.formData.main_parameter)
                  break
                case "31":
                  this.infraredProbeParams=JSON.parse(this.formData.main_parameter)
                  break
                case "32":
                  this.powerDistributionParams=JSON.parse(this.formData.main_parameter)
                  break
                case "33":
                  this.luminairesParams=JSON.parse(this.formData.main_parameter)
                  break
                case "34":
                  this.pipelineParams=JSON.parse(this.formData.main_parameter)
                  break
                case "35":
                  this.containerFacilitiesParams=JSON.parse(this.formData.main_parameter)
                  break
                case "36":
                  this.outdoorAirConditioningParams=JSON.parse(this.formData.main_parameter)
                  break
                case "37":
                  this.indoorAirConditioningParams=JSON.parse(this.formData.main_parameter)
                  break
                case "38":
                  this.fireRollingShutterParams=JSON.parse(this.formData.main_parameter)
                  break
                case "39":
                  this.buildingIntercomParams=JSON.parse(this.formData.main_parameter)
                  break
                case "40":
                  this.HydraulicParkingRackParams=JSON.parse(this.formData.main_parameter)
                  break
                case "41":
                  this.barrierFateParams=JSON.parse(this.formData.main_parameter)
                  break
              }
              this.$nextTick(function () {
                this.bindQRCode();
              })
            }
          })
          this.queryParams.monitorpointnum=this.detailData.monitorpointnum
          this.queryParams.equipment_num=this.detailData.equipment_num
          this.getTableData(true);
        })
      }
    },
    confirm() {
      this.getSuperiorInfoList();
      this.getSubordinateInfoList();
      if (this.showType == 'add' || this.showType == 'edit' || this.showType === 'paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            switch (this.formData.equipment_param_type) {
              case "0":
                this.formData.main_parameter=JSON.stringify(this.highVoltageCabinetParams)
                break
              case "1":
                this.formData.main_parameter=JSON.stringify(this.DCScreenParams)
                break
              case "2":
                this.formData.main_parameter=JSON.stringify(this.transformerParams)
                break
              case "3":
                this.formData.main_parameter=JSON.stringify(this.lowVoltageCabinetParams)
                break
              case "4":
                this.formData.main_parameter=JSON.stringify(this.compensationCabinetParams)
                break
              case "5":
                this.formData.main_parameter=JSON.stringify(this.dieselGeneratorParams)
                break
              case "6":
                this.formData.main_parameter=JSON.stringify(this.computerParams)
                break
              case "7":
                this.formData.main_parameter=JSON.stringify(this.fireEngineParams)
                break
              case "8":
                this.formData.main_parameter=JSON.stringify(this.freTelephoneHostParams)
                break
              case "9":
                this.formData.main_parameter=JSON.stringify(this.radioAmplifierParams)
                break
              case "10":
                this.formData.main_parameter=JSON.stringify(this.videoMatrixParams)
                break
              case "11":
                this.formData.main_parameter=JSON.stringify(this.videoRecorderParams)
                break
              case "12":
                this.formData.main_parameter=JSON.stringify(this.moduleBoxParams)
                break
              case "13":
                this.formData.main_parameter=JSON.stringify(this.splitWaterPumpParams)
                break
              case "14":
                this.formData.main_parameter=JSON.stringify(this.integratedWaterPumpParams)
                break
              case "15":
                this.formData.main_parameter=JSON.stringify(this.coolingWaterMachineParams)
                break
              case "16":
                this.formData.main_parameter=JSON.stringify(this.coolingTowerParams)
                break
              case "17":
                this.formData.main_parameter=JSON.stringify(this.hotWaterBoilerParams)
                break
              case "18":
                this.formData.main_parameter=JSON.stringify(this.plateHeatExchangerParams)
                break
              case "19":
                this.formData.main_parameter=JSON.stringify(this.airHandlingUnitParams)
                break
              case "20":
                this.formData.main_parameter=JSON.stringify(this.fanParams)
                break
              case "21":
                this.formData.main_parameter=JSON.stringify(this.tractionLadderParams)
                break
              case "22":
                this.formData.main_parameter=JSON.stringify(this.hydraulicLadderParams)
                break
              case "23":
                this.formData.main_parameter=JSON.stringify(this.escalatorParams)
                break
              case "24":
                this.formData.main_parameter=JSON.stringify(this.windowCleanerParams)
                break
              case "25":
                this.formData.main_parameter=JSON.stringify(this.smokeSensationParams)
                break
              case "26":
                this.formData.main_parameter=JSON.stringify(this.warmFeelingParams)
                break
              case "27":
                this.formData.main_parameter=JSON.stringify(this.breakingGlassParams)
                break
              case "28":
                this.formData.main_parameter=JSON.stringify(this.fireModuleParams)
                break
              case "29":
                this.formData.main_parameter=JSON.stringify(this.fireExtensionParams)
                break
              case "30":
                this.formData.main_parameter=JSON.stringify(this.videoCameraParams)
                break
              case "31":
                this.formData.main_parameter=JSON.stringify(this.infraredProbeParams)
                break
              case "32":
                this.formData.main_parameter=JSON.stringify(this.powerDistributionParams)
                break
              case "33":
                this.formData.main_parameter=JSON.stringify(this.luminairesParams)
                break
              case "34":
                this.formData.main_parameter=JSON.stringify(this.pipelineParams)
                break
              case "35":
                this.formData.main_parameter=JSON.stringify(this.containerFacilitiesParams)
                break
              case "36":
                this.formData.main_parameter=JSON.stringify(this.outdoorAirConditioningParams)
                break
              case "37":
                this.formData.main_parameter=JSON.stringify(this.indoorAirConditioningParams)
                break
              case "38":
                this.formData.main_parameter=JSON.stringify(this.fireRollingShutterParams)
                break
              case "39":
                this.formData.main_parameter=JSON.stringify(this.buildingIntercomParams)
                break
              case "40":
                this.formData.main_parameter=JSON.stringify(this.HydraulicParkingRackParams)
                break
              case "41":
                this.formData.main_parameter=JSON.stringify(this.barrierFateParams)
                break
            }
            this.formData.equipment_num=this.equipment_num_before+this.formData.equipment_num
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add' || this.showType === 'paste') {
              this.showLoading();
              addEquipDirectories(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyEquipDirectories(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    equipmentPicUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.equipment_pic = file.response.urlPath;
        }
        return file;
      });
      this.equipmentPicList = fileList;
    },
    logoPicUpload(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.urlPath;
          this.formData.logo_pic = file.response.urlPath;
        }
        return file;
      });
      this.logoPicList = fileList;
    },
    async handlePreview(url) {
      this.previewImage = this.baseurl + url;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    getEquipmentCategory(devicecode){
      this.createEquipmentNum()
      getSecondaryDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentCategoryList=res.item
        }
      })
    },
    addLowVoltageCabinetParams(index){
      if(index>1){
        for(let i=1;i<index;i++){
          this.lowVoltageCabinetParams.affiliatedParams.push(
              {
                //开关
                switch_model: '',
                switch_rated_voltage: '',
                switch_rated_current: '',
                switch_form: '',
                switch_release_current: '',
                switch_breaking_capacity: '',
                //电流互感器
                current_transformer_model: '',
                current_transformer_rated_capacity: '',
                current_transformer_ratio: '',
                current_transformer_num: '',
                current_transformer_voltage: '',
                current_transformer_rated_current1: '',
                current_transformer_accuracy_level: '',
                current_transformer_rated_current2: '',
              },
          )
        }
      }
    },
    addPowerDistributionParams(index){
      if(index>1){
        for(let i=1;i<index;i++){
          this.powerDistributionParams.affiliatedParams.push(
              {
                //5.开关/接触器
                contactor_model: '',
                contactor_rated_voltage: '',
                contactor_rated_current: '',
                contactor_form: '',
                suction_coil_voltage: '',
                auxiliary_contact_num: '',
                //6.热继电器
                thermal_relay_model: '',
                thermal_relay_rated_voltage : '',
                current_regulation_range: '',
                thermal_relay_num: '',
                thermal_relay_rated_current: '',
                reset_mode: '',
                //7.电流互感器
                current_transformer_model: '',
                current_transformer_rated_capacity: '',
                current_transformer_ratio: '',
                current_transformer_num: '',
                current_transformer_voltage: '',
                current_transformer_rated_current1: '',
                current_transformer_accuracy_level: '',
                current_transformer_rated_current2: '',
              },
          )
        }
      }
    },
    getEquipmentChildCategory(devicecode){
      this.formData.equipment_child_category=""
      this.createEquipmentNum()
      getThirdDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentChildCategoryList=res.item
        }
      })
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let maintenance_order_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'maintenance_order_id', maintenance_order_id);
      if(type == 'delete') {
        this.deleteConfirm(maintenance_order_id, record)
      }else{
        this.maintenanceTaskDetailData=record
        this.maintenanceTaskShowType=type
        this.maintenanceTaskVisible=true
      }
    },
    getTableData(firstPage){
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        order_date:moment().year(),
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMaintenanceOrderListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
      this.queryParams.process_node="71"
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.deviceCodeList = dictionary.deviceCodeList
      }
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.maintenanceTaskDetailData=record
            this.maintenanceTaskShowType='detail'
            this.maintenanceTaskVisible=true
          },
        },
      }
    },
    bindQRCode: function () {
      if(this.$refs.qrCodeDiv){
        this.$refs.qrCodeDiv.innerHTML = "" //清除上一个二维码
      }
      new QRCode(this.$refs.qrCodeDiv, {
        text: this.formData.monitorpointnum+'-'+this.equipment_num_before+this.formData.equipment_num,
        width: 150,
        height: 150,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      })
    },
    exportEquipmentIDCard(record){
      let params={
        ...record
      }
      exportEquipmentIDCardWord(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.$message.success("操作成功")
        }
      })
    },
    filterOption(input, option) {
      // 重要的一步，可以console.log(input)看下输入的是啥
      this.equipmentList.superior_name_1 = input // 在此前是可以手动输入，但鼠标离开后，输入的内容不能在输入框内保存，将input的值给到a-select标签的v-model绑定的newSoftName即可实现将手动输入的值赋值给input
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },


    resetQueryParam() {
      this.queryParam={
        equipment_name1:'',
        equipment_name2:'',
        equipment_category1:'',
        equipment_child_category1:'',
        equipment_category2:'',
        equipment_child_category2:'',
      }
    },
    //上级设备添加
    getEquipDirectoriesList1(){
      getEquipDirectoriesList({
        monitorpointnum:this.formData.monitorpointnum,
        equipment_type:this.formData.equipment_type,
        equipment_category:this.queryParam.equipment_category1,
        equipment_child_category:this.queryParam.equipment_child_category1,
        is_history:'0'
      }).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentNumList1=res.item
        }
      })
    },
    getEquipmentChildCategory1(devicecode){
      getThirdDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentChildCategoryList1=res.item
          this.getEquipDirectoriesList1()
        }
      })
    },
    onChange1(option){
      getEquipDirectoriesListByCondition({relevance_name:option}).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            if(this.arr1.length===this.index1+1){
              this.arr1[this.index1].name=res.item[0].equipment_name
              this.arr1[this.index1].num=res.item[0].equipment_num
              this.arr1[this.index1].address=res.item[0].address
            }else if(this.arr1.length===this.index1){
              this.arr1[this.index1-1].name=res.item[0].equipment_name
              this.arr1[this.index1-1].num=res.item[0].equipment_num
              this.arr1[this.index1-1].address=res.item[0].address
            }else if(this.index1===0){
              this.arr1=[{
                name:res.item[0].equipment_name,
                num:res.item[0].equipment_num,
                address:res.item[0].address
              }]
            }
          }
        }
      })
    },
    dynamicFormChange1(val){
      this.index1=val.index
      if(val.type==='新增一行'){
        this.arr1.push({
          name:"",
          num:"",
          address:""
        })
      }else if(val.type==='移除一行'){
        this.arr1.splice(val.index, 1)
        this.formData.superior_name="";
        this.formData.superior_num="";
        this.formData.superior_position="";
        for(let i=0;i<this.arr1.length;i++){
          this.formData.superior_name+=this.arr1[i].name+";"
          this.formData.superior_num+=this.arr1[i].num+";"
          this.formData.superior_position+=this.arr1[i].address+";"
        }
      }
    },
    getSuperiorInfoList(){
      const {form: {validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          const partOneArr = []
          values[`${PARTONE}Name`].forEach((item, index) => {
            const obj = {
              name: values[`${PARTONE}Name`][index],
              num: values[`${PARTONE}Num`][index],
              address: values[`${PARTONE}Address`][index],
            }
            partOneArr.push(obj)
          })
          this.formData.superior_name="";
          this.formData.superior_num="";
          this.formData.superior_position="";
          for(let i=0;i<partOneArr.length;i++){
            this.formData.superior_name+=partOneArr[i].name+";"
            this.formData.superior_num+=partOneArr[i].num+";"
            this.formData.superior_position+=partOneArr[i].address+";"
          }
        }
      })
    },
    //下级设备添加
    getEquipDirectoriesList2(){
      getEquipDirectoriesList({
        monitorpointnum:this.formData.monitorpointnum,
        equipment_type:this.formData.equipment_type,
        equipment_category:this.queryParam.equipment_category2,
        equipment_child_category:this.queryParam.equipment_child_category2,
        is_history:'0'
      }).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentNumList2=res.item
        }
      })
    },
    getEquipmentChildCategory2(devicecode){
      getThirdDeviceList({devicecode:devicecode}).then(res=>{
        if(res&&res.returncode=='0'){
          this.equipmentChildCategoryList2=res.item
          this.getEquipDirectoriesList2()
        }
      })
    },
    onChange2(option){
      getEquipDirectoriesListByCondition({relevance_name:option}).then(res=>{
        if(res&&res.returncode=='0'){
          if(res.item.length>0){
            if(this.arr2.length===this.index2+1){
              this.arr2[this.index2].name=res.item[0].equipment_name
              this.arr2[this.index2].num=res.item[0].equipment_num
              this.arr2[this.index2].address=res.item[0].address
            }else if(this.arr2.length===this.index2){
              this.arr2[this.index2-1].name=res.item[0].equipment_name
              this.arr2[this.index2-1].num=res.item[0].equipment_num
              this.arr2[this.index2-1].address=res.item[0].address
            }else if(this.index2===0){
              this.arr2=[{
                name:res.item[0].equipment_name,
                num:res.item[0].equipment_num,
                address:res.item[0].address
              }]
            }
          }
        }
      })
    },
    dynamicFormChange2(val){
      this.index2=val.index
      if(val.type==='新增一行'){
        this.arr2.push({
          name:"",
          num:"",
          address:""
        })
      }else if(val.type==='移除一行'){
        this.arr2.splice(val.index, 1)
        this.formData.subordinate_name="";
        this.formData.subordinate_num="";
        this.formData.subordinate_position="";
        for(let i=0;i<this.arr2.length;i++){
          this.formData.subordinate_name+=this.arr2[i].name+";"
          this.formData.subordinate_num+=this.arr2[i].num+";"
          this.formData.subordinate_position+=this.arr2[i].address+";"
        }
      }
    },
    getSubordinateInfoList(){
      const {form: {validateFields } } = this
      validateFields((errors, values) => {
        if (!errors) {
          const partOneArr = []
          values[`${PARTTWO}Name`].forEach((item, index) => {
            const obj = {
              name: values[`${PARTTWO}Name`][index],
              num: values[`${PARTTWO}Num`][index],
              address: values[`${PARTTWO}Address`][index],
            }
            partOneArr.push(obj)
          })
          this.formData.subordinate_name="";
          this.formData.subordinate_num="";
          this.formData.subordinate_position="";
          for(let i=0;i<partOneArr.length;i++){
            this.formData.subordinate_name+=partOneArr[i].name+";"
            this.formData.subordinate_num+=partOneArr[i].num+";"
            this.formData.subordinate_position+=partOneArr[i].address+";"
          }
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>